import React, { useEffect, useState } from "react";
import { IonApp } from "@ionic/react";
import Axios from "axios";
import useAlert from "./hooks/useAlert";
import { AuthContext } from "./context/AuthContext";
import GetApiErrorStr from "./helpers/GetApiErrorStr";
import AppTabs from "./AppTabs";
import { Capacitor } from "@capacitor/core";
import useToast from "./hooks/useToast";
import dayjs from "dayjs";
import { App as CapacitorApp } from '@capacitor/app';

document.addEventListener('wheel', function(e) {
    if (document.activeElement && document.activeElement.tagName === "INPUT" && document.activeElement["type"] === "number")
    e.preventDefault();
}, { passive: false });

const App: React.FC = () => {
    const [userInfo, setUserInfo] = useState({
        email_verified_at: localStorage.getItem("email_verified_at"),
    });
    const [authStatus, setAuthStatus] = useState({
        loggedIn: Boolean(localStorage.getItem("authToken")),
        token: localStorage.getItem("authToken"),
    });
    const { addAlert } = useAlert();
    const { addToast } = useToast();
    const [lastBackButtonTime, _setLastBackButtonTime] = useState<dayjs.Dayjs>(
        dayjs()
    );
    const lastBackButtonTimeRef = React.useRef(lastBackButtonTime);
    const setLastBackButtonTime = (data) => {
        lastBackButtonTimeRef.current = data;
        _setLastBackButtonTime(data);
    };

    CapacitorApp.addListener("appRestoredResult", (data) => {
        addToast(
            "Kevés rendelkezésre álló memória miatt az előző művelet megismétlése szükséges",
            5000
        );
    });

    useEffect(() => {
        setAuth(
            Boolean(localStorage.getItem("authToken")),
            localStorage.getItem("authToken")
        );
        if (Capacitor.isNativePlatform()) {
            CapacitorApp.addListener("backButton", (e) => {
                if (
                    window.location.pathname === "/news" ||
                    window.location.pathname === "/my/dashboard"
                ) {
                    const now = dayjs();
                    if (now.diff(lastBackButtonTimeRef.current, "second") > 2) {
                        addToast("Nyomja meg még egyszer a kilépéshez", 2000);
                    } else {
                        CapacitorApp.exitApp();
                    }
                    setLastBackButtonTime(dayjs());
                } else {
                    const backdrops = document.getElementsByTagName(
                        "ion-backdrop"
                    );
                    if (backdrops && backdrops.length) {
                        backdrops[0].click();
                    } else {
                        window.history.back();
                    }
                }
            });
        }
    }, []);

    Axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                setLogout(true);
            } else {
                addAlert("Hiba", GetApiErrorStr(error));
                throw error;
            }
        }
    );

    const setAuth = async (login: boolean, token?: string) => {
        if (login) {
            Axios.get("/user", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                setUserInfo(res.data.data);
            });
        }
        setAuthStatus({ loggedIn: login, token: token });

        if (localStorage.getItem("authToken") !== token) {
            localStorage.setItem("authToken", token);
        }
    };

    useEffect(() => {
        localStorage.setItem(
            "email_verified_at",
            userInfo ? userInfo.email_verified_at : null
        );
    }, [userInfo]);

    const setLogout = (isForced?: boolean) => {
        if (isForced) {
            setAuth(false, "");
            setUserInfo(null);
        } else {
            Axios.get("/logout", {
                headers: {
                    Authorization: `Bearer ${authStatus.token}`,
                },
            })
                .then((res) => {
                    setAuth(false, "");
                    setUserInfo(null);
                    if (Capacitor.isNative) {
                        window.location.replace("/news");
                    } else {
                        window.location.replace("/my/dashboard");
                    }
                })
                .catch((err) => {
                    setAuth(false, "");
                    setUserInfo(null);
                });
        }
    };

    return (
        <IonApp>
            <AuthContext.Provider
                value={{
                    loggedIn: authStatus.loggedIn,
                    token: authStatus.token,
                    userInfo: userInfo,
                    setUserInfo: setUserInfo,
                    setLogout: setLogout,
                    setAuth: setAuth,
                }}
            >
                <AppTabs />
            </AuthContext.Provider>
        </IonApp>
    );
};

export default App;
