import React, { useState } from "react";
import { IonButton } from "@ionic/react";
import { useAuth } from "../context/AuthContext";
import Axios from "axios";

import PageWrapper from "../layout/PageWrapper";
import useLoading from "../hooks/useLoading";
import Input from "../helpers/form/FormItem";
import { PinElement } from "../helpers/form/RegistrationVerifyElements";
import { changedHandler } from "../helpers/Validation";
import { CheckValidation, GetFormValues } from "../helpers/form/GetFormValues";
import useAlert from "../hooks/useAlert";
import { Capacitor } from "@capacitor/core";

const RegistrationPage: React.FC = () => {
    const { addLoading, removeLoading } = useLoading();
    const { addAlert } = useAlert();
    const { token, userInfo, setUserInfo, setLogout } = useAuth();

    const [inputData, setInputData] = useState([PinElement]);

    /*
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    */

    const handleSendPin = async () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            const values = GetFormValues(inputData);
            Axios.post("/register-confirm", values, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    let newUserInfo = { ...userInfo };
                    newUserInfo.email_verified_at = res.data.date;
                    if (Capacitor.isNative) {
                        window.location.replace("/news");
                    } else {
                        window.location.replace("/my/dashboard");
                    }
                    setUserInfo(newUserInfo);
                    removeLoading();
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            setInputData(ValidObj["elements"]);
            removeLoading();
        }
    };

    const handleResendPin = async () => {
        addLoading();
        Axios.get("/new-pin", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                removeLoading();
                addAlert("Siker", "PIN kód újraküldve");
            })
            .catch((err) => {
                removeLoading();
            });
    };

    return (
        <PageWrapper gridClassName="authentication-page-grid">
            <h1 className="page-title">Ellenőrzés</h1>

            <p>
                <b>Köszönjük érdeklődését!</b>
            </p>
            <p>
                A megadott e-mail címre ({userInfo ? userInfo.email ?? "" : ""})
                egy megerősítő levelet küldtünk. Kérjük adja meg az abban
                található PIN kódot.
            </p>

            <div className="blue-form">
                {inputData.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputData,
                                setInputData,
                                event
                            )
                        }
                    />
                ))}
                <br />
                <div className="ion-text-center">
                    <IonButton
                        size="large"
                        expand="block"
                        shape="round"
                        color="secondary"
                        className="large-btn"
                        onClick={handleSendPin}
                    >
                        PIN beküldése
                    </IonButton>
                    <br />
                    <p>Nem érkezett meg a kód?</p>
                    <IonButton
                        size="large"
                        expand="block"
                        shape="round"
                        className="large-btn medium-btn"
                        onClick={handleResendPin}
                    >
                        Új kód kérése
                    </IonButton>
                    <IonButton
                        size="large"
                        expand="block"
                        shape="round"
                        className="large-btn medium-btn"
                        onClick={setLogout}
                    >
                        Regisztráció újrakezdése
                    </IonButton>
                </div>
            </div>
        </PageWrapper>
    );
};

export default RegistrationPage;
