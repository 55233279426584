import FormElement from "../../models/FormElement";
import { OnlyNumberEvent } from "../../helpers/form/EnabledCharacters";

export const TemplateElement: FormElement = {
    keyname: "template",
    type: "select",
    elementConfig: {
        name: "template",
        needEmptyItem: true,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Sablon",
    value: "-1",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const TemplateNameElement: FormElement = {
    keyname: "name",
    type: "input",
    elementConfig: {
        name: "name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Sablon neve (pl. Lakás, Nagyi ...)",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerNameElement: FormElement = {
    keyname: "customer_name",
    type: "input",
    elementConfig: {
        name: "customer_name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználó neve",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerAddressElement: FormElement = {
    keyname: "place_of_consumption",
    type: "input",
    elementConfig: {
        name: "place_of_consumption",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználási hely címe",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerIDElement: FormElement = {
    keyname: "customer_id",
    type: "input",
    elementConfig: {
        name: "customer_id",
        type: "number",
        onKeyDown: OnlyNumberEvent,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználó azonosító",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const CommentElement: FormElement = {
    keyname: "comment",
    type: "textarea",
    elementConfig: {
        name: "comment",
        placeholder: "Megjegyzés...",
        autoGrow: true,
        maxlength: 1000,
    },
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
    labelConfig: {
        position: "floating",
    },
    labelText: "Megjegyzés",
};
