import React, { useState } from "react";
import { IonButton, IonRow, IonCol, IonRouterLink } from "@ionic/react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Axios from "axios";
import { EmailElement } from "../helpers/form/RegistrationPageElements";
import PageWrapper from "../layout/PageWrapper";
import { changedHandler } from "../helpers/Validation";
import Input from "../helpers/form/FormItem";
import useLoading from "../hooks/useLoading";
import { CheckValidation, GetFormValues } from "../helpers/form/GetFormValues";
import "./FormPages.scss";

const LostPasswordPage: React.FC = () => {
    const { loggedIn, userInfo } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const [sentAddress, setSentAddress] = useState();

    const [inputData, setInputData] = useState([EmailElement]);

    const handleSendPasswordRecovery = async () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            const values = GetFormValues(inputData);
            Axios.post("/lost-password", values)
                .then((res) => {
                    removeLoading();
                    setSentAddress(values["email"]);
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            setInputData(ValidObj["elements"]);
            removeLoading();
        }
    };

    if (loggedIn && userInfo && userInfo.email_verified_at != null) {
        return <Redirect to="/my/dashboard" />;
    }
    if (sentAddress) {
        return <Redirect to={`/password-recovery/${sentAddress}`} />;
    }

    return (
        <PageWrapper gridClassName="lostpassword-page-grid">
            <h1 className="page-title">Elfelejtett jelszó</h1>

            <form className="form">
                {inputData.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputData,
                                setInputData,
                                event
                            )
                        }
                    />
                ))}

                <IonRow>
                    <IonCol size="6">
                        <IonRouterLink
                            routerDirection="back"
                            routerLink="/login"
                        >
                            Vissza
                        </IonRouterLink>
                    </IonCol>
                </IonRow>
                <br />
                <div className="ion-text-center">
                    <IonButton
                        size="large"
                        expand="block"
                        onClick={handleSendPasswordRecovery}
                    >
                        Emlékeztető küldése
                    </IonButton>
                </div>
                <br />
            </form>
        </PageWrapper>
    );
};

export default LostPasswordPage;
