import React, { Dispatch, SetStateAction, useContext } from "react";

export interface Auth {
    loggedIn: boolean;
    token?: string;
    userInfo: any;
    setUserInfo: Dispatch<SetStateAction<any>>;
    setLogout: () => void;
    setAuth: (login: boolean, token?: string) => void;
}

export const AuthContext = React.createContext<Auth>({
    loggedIn: false,
    userInfo: null,
    setUserInfo: null,
    setLogout: () => {},
    setAuth: (login: boolean, token?: string) => {},
});

export function useAuth(): Auth {
    return useContext(AuthContext);
}
