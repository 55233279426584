import FormElement from "../../models/FormElement";
import { OnlyNumberEvent } from "../../helpers/form/EnabledCharacters";

export const PinElement: FormElement = {
    keyname: "pin",
    type: "input",
    elementConfig: {
        name: "pin",
        type: "number",
        onKeyDown: OnlyNumberEvent,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "PIN kód",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};