import FormElement from "../../models/FormElement";

export const EmailElement: FormElement = {
    keyname: "email",
    type: "input",
    elementConfig: {
        name: "email",
        type: "text",
        readonly: false,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "E-mail cím",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const FullNameElement: FormElement = {
    keyname: "name",
    type: "input",
    elementConfig: {
        name: "name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Teljes név",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const PhoneElement: FormElement = {
    keyname: "phone",
    type: "input",
    elementConfig: {
        name: "phone",
        type: "tel",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Telefonszám",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const PostcodeElement: FormElement = {
    keyname: "postal_code",
    type: "input",
    elementConfig: {
        name: "postal_code",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Irányítószám",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const CityElement: FormElement = {
    keyname: "settlement",
    type: "input",
    elementConfig: {
        name: "settlement",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Város",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const StreetElement: FormElement = {
    keyname: "address",
    type: "input",
    elementConfig: {
        name: "address",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Utca, házszám",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const NewPasswordElement: FormElement = {
    keyname: "password",
    type: "input",
    elementConfig: {
        name: "password",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Új jelszó",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConfirmNewPasswordElement: FormElement = {
    keyname: "password_confirmation",
    type: "input",
    elementConfig: {
        name: "password_confirmation",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Jelszó megerősítése",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const OldPasswordElement: FormElement = {
    keyname: "old_password",
    type: "input",
    elementConfig: {
        name: "old_password",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Régi jelszó",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
