import { IonButton, IonCol, IonIcon, IonImg, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import FormElement from "../../models/FormElement";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { camera } from "ionicons/icons";

import "./Image.scss";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const ImagePickerAndCapture: React.FC<Props> = ({ formElement, changed }) => {
    const [photos, setPhotos] = useState([]);

    if (formElement.forcedClear) {
        setPhotos([]);
    }

    useEffect(() => {
        if (photos.length) {
            getFile(photos[0]);
        }
    }, [photos]);
    
    const getFile = async (path) => {
        const response = await fetch(path);
        const blob = await response.blob();
        changed({ target: { value: blob } });
    };

    const handleGetPicture = async () => {
        const photo = await Camera.getPhoto({
            quality: 70,
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
            promptLabelHeader: "Kép kiválasztása",
            promptLabelCancel: "Mégse",
            promptLabelPhoto: "Kép kiválasztása Galériából",
            promptLabelPicture: "Új fénykép készítése",
            saveToGallery: true,
            width: 800,
        });
        setPhotos([photo.webPath]);
    };

    const imageElements = photos.map((photo, index) => (
        <IonCol size="12" key={index}>
            <IonImg src={photo} />
        </IonCol>
    ));

    return (
        <div>
            <br />
            <IonButton
                expand="full"
                shape="round"
                color="secondary"
                onClick={handleGetPicture}
            >
                <IonIcon slot="start" icon={camera} />
                Kép feltöltése
            </IonButton>
            <IonRow>{imageElements}</IonRow>
        </div>
    );
};
export default ImagePickerAndCapture;
