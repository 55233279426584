import dayjs from "dayjs";
import FormElement from "../../models/FormElement";
import { OnlyNumberEvent } from "../../helpers/form/EnabledCharacters";

export const TemplateElement: FormElement = {
  keyname: "template_id",
  type: "select",
  elementConfig: {
    name: "template_id",
    placeholder: "Sablon kiválasztása...",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Sablon",
  value: "",
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const ConsumerIDElement: FormElement = {
  keyname: "customer_id",
  type: "input",
  elementConfig: {
    name: "customer_id",
    type: "number",
    onKeyDown: OnlyNumberEvent,
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Felhasználó azonosító",
  value: "",
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const CustomerNameElement: FormElement = {
  keyname: "customer_name",
  type: "input",
  elementConfig: {
    name: "customer_name",
    type: "text",
    autocapitalize: "words",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Felhasználó neve",
  value: "",
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const PlaceOfConsumptionElement: FormElement = {
  keyname: "place_of_consumption",
  type: "input",
  elementConfig: {
    name: "place_of_consumption",
    type: "text",
    autocapitalize: "words",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Felhasználási hely címe",
  value: "",
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const CommentElement: FormElement = {
  keyname: "comment",
  type: "textarea",
  elementConfig: {
    name: "comment",
    placeholder: "Megjegyzés...",
    autoGrow: true,
    maxlength: 1000,
  },
  value: "",
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
  labelConfig: {
    position: "floating",
  },
  labelText: "Megjegyzés",
};
export const MeterSerialElement: FormElement = {
  keyname: "meter_id",
  type: "input",
  elementConfig: {
    name: "meter_id",
    type: "text",
    autocapitalize: "characters",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Gyári száma (Mérőcsere esetén javítandó!)",
  value: "",
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const MeterNameElement: FormElement = {
  keyname: "meter_name",
  type: "input",
  elementConfig: {
    name: "meter_name",
    type: "text",
    autocapitalize: "words",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Megnevezés",
  value: "",
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const MeterPositionElement: FormElement = {
  keyname: "meter_reading",
  type: "input",
  elementConfig: {
    name: "meter_reading",
    type: "number",
    onKeyDown: OnlyNumberEvent,
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Óraállás (m3)",
  value: "",
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
    {
      type: "maxlength",
      value: 6,
      message: "Maximum 6 számjegy",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const MeterDateElement: FormElement = {
  keyname: "report_time",
  type: "date",
  elementConfig: {
    name: "report_time",
    displayFormat: "YYYY.MM.DD.",
  },
  labelConfig: {
    position: "stacked",
  },
  labelText: "Dátum",
  value: dayjs(new Date()).format("YYYY-MM-DD"),
  validity: [
    {
      type: "required",
      message: "Kötelező mező",
    },
  ],
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const MeterPickerOrCaptureElement: FormElement = {
  keyname: "img",
  type: "imagepickerandcapture",
  elementConfig: {
    name: "img",
  },
  labelConfig: {},
  value: "",
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
export const TemplateNameElement: FormElement = {
  keyname: "template_name",
  type: "input",
  elementConfig: {
    name: "template_name",
    type: "text",
    autocapitalize: "words",
  },
  labelConfig: {
    position: "floating",
  },
  labelText: "Sablon neve (pl. Lakás, Nagyi ...)",
  value: "",
  valid: false,
  touched: false,
  validationErrorMessage: "",
  item: {},
};
