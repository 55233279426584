import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const Select: React.FC<Props> = ({ formElement, changed }) => {
    let labelElement = null;
    let inputElement = null;
    let selectOptions = null;

    if (formElement.labelConfig || formElement.labelText) {
        labelElement = (
            <IonLabel {...formElement.labelConfig}>
                {formElement.labelText ?? ""}
            </IonLabel>
        );
    }
    if (
        formElement.elementConfig.options ||
        formElement.elementConfig.needEmptyItem
    ) {
        let tempOptions = [];
        if (formElement.elementConfig.needEmptyItem) {
            tempOptions.push({ name: "Válasszon...", value: "-1" });
        }
        if (formElement.elementConfig.options) {
            formElement.elementConfig.options.map((x) => tempOptions.push(x));
        }
        selectOptions = tempOptions.map((optionData, index) => (
            <IonSelectOption key={index} value={optionData.value}>
                {optionData.name}
            </IonSelectOption>
        ));
    }
    inputElement = (
        <IonSelect
            {...formElement.elementConfig}
            value={formElement.value ?? ""}
            onIonChange={changed}
            onIonBlur={changed}
            okText="Kiválaszt"
            cancelText="Mégse"
        >
            {selectOptions}
        </IonSelect>
    );

    return (
        <IonItem {...formElement.item}>
            {labelElement}
            {inputElement}
        </IonItem>
    );
};
export default Select;
