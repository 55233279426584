import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const Checkbox: React.FC<Props> = ({ formElement, changed }) => {
    let labelElement = null;
    let inputElement = null;

    if (formElement.labelConfig || formElement.labelText) {
        labelElement = (
            <IonLabel {...formElement.labelConfig}>
                &nbsp;{formElement.labelText ?? ""}
            </IonLabel>
        );
    }
    inputElement = (
        <IonCheckbox
            {...formElement.elementConfig}
            onIonChange={changed}
            onIonBlur={changed}
            value={formElement.value}
        />
    );

    return (
        <IonItem {...formElement.item}>
            {inputElement}
            {labelElement}
        </IonItem>
    );
};
export default Checkbox;
