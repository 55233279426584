/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import { useAuth } from "../../context/AuthContext";
import {
    IonAlert,
    IonButton,
    IonCheckbox,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonText,
    useIonViewWillEnter,
} from "@ionic/react";
import { documentText, save } from "ionicons/icons";
import {
    EmailElement,
    FullNameElement,
    PhoneElement,
    PostcodeElement,
    CityElement,
    StreetElement,
    NewPasswordElement,
    ConfirmNewPasswordElement,
    OldPasswordElement,
} from "../../helpers/form/SettingsElements";
import FormItem from "../../helpers/form/FormItem";
import { changedHandler } from "../../helpers/Validation";
import useLoading from "../../hooks/useLoading";
import {
    CheckValidation,
    GetFormValues,
} from "../../helpers/form/GetFormValues";
import Axios from "axios";
import useToast from "../../hooks/useToast";
import SetFormValues from "../../helpers/form/SetFormValues";
import { scrollTo } from "../../helpers/Scroller";
import SetPasswordShowIcon from "../../helpers/form/SetPasswordShowIcon";
interface Props {
    onLogOut: () => void;
}

const SettingsPage: React.FC<Props> = ({ onLogOut }) => {
    const { token, userInfo, setUserInfo, setAuth } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const { addToast } = useToast();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showDeleteUserAlert, setShowDeleteUserAlert] = useState(false);
    const [showDeleteUserMissingCheck, setShowDeleteUserMissingCheck] =
        useState(false);
    const [deleteUserCheck, setDeleteUserCheck] = useState(false);

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[6] = SetPasswordShowIcon(
            TempInputData[6],
            showOldPassword,
            setShowOldPassword
        );
        setInputData(TempInputData);
    }, [showOldPassword]);

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[7] = SetPasswordShowIcon(
            TempInputData[7],
            showNewPassword,
            setShowNewPassword
        );
        setInputData(TempInputData);
    }, [showNewPassword]);

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[8] = SetPasswordShowIcon(
            TempInputData[8],
            showConfirmPassword,
            setShowConfirmPassword
        );
        setInputData(TempInputData);
    }, [showConfirmPassword]);

    const [inputData, setInputData] = useState([
        EmailElement,
        FullNameElement,
        PhoneElement,
        PostcodeElement,
        CityElement,
        StreetElement,
        SetPasswordShowIcon(
            OldPasswordElement,
            showOldPassword,
            setShowOldPassword
        ),
        SetPasswordShowIcon(
            NewPasswordElement,
            showNewPassword,
            setShowNewPassword
        ),
        SetPasswordShowIcon(
            ConfirmNewPasswordElement,
            showConfirmPassword,
            setShowConfirmPassword
        ),
    ]);

    useEffect(() => {
        if (
            !inputData[8].validity ||
            inputData[8].validity.length !== 1 ||
            inputData[7].value != inputData[8].validity[0].value
        ) {
            let tempInputData = [...inputData];
            let tempPasswordConfirm = { ...tempInputData[8] };
            tempPasswordConfirm.validity = [
                {
                    type: "equal",
                    value: inputData[7].value,
                    message: "A két jelszó nem egyezik",
                },
            ];
            tempInputData[8] = tempPasswordConfirm;
            setInputData(tempInputData);
        }
    }, [inputData]);

    useEffect(() => {
        setInputData(SetFormValues(inputData, userInfo));
    }, [userInfo]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleDeleteUser = async () => {
        addLoading();
        Axios.delete("/delete-user", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                setAuth(false, "");
                setUserInfo(null);
                addToast("Sikeres törlés", 5000);
            })
            .finally(() => {
                removeLoading();
            });
    };

    useIonViewWillEnter(() => {
        setDeleteUserCheck(false);
    });

    const onUpdateUserData = async () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            const values = GetFormValues(inputData);
            Axios.post("/update-profile", values, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    setUserInfo(res.data.user);
                    removeLoading();
                    if (res.data.user.email_change_requests.length > 0) {
                        addToast(
                            "Sikeres mentés, viszont az e-mail cím módosítása megerősítést igényel.",
                            5000
                        );
                    } else {
                        addToast("Sikeres mentés", 5000);
                    }
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            setInputData(ValidObj["elements"]);
            scrollTo(".errorMessage");
            removeLoading();
        }
    };

    return (
        <PageWrapper>
            <h1 className="page-title">Profil</h1>

            <form className="form" onSubmit={handleSubmit}>
                {inputData.map((element, index) => (
                    <FormItem
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputData,
                                setInputData,
                                event
                            )
                        }
                    />
                ))}

                <br />
                <IonRow>
                    <IonCol size="12">
                        <IonButton
                            id="loginButton"
                            size="large"
                            expand="block"
                            shape="round"
                            color="secondary"
                            className="large-btn"
                            onClick={onUpdateUserData}
                        >
                            <IonIcon slot="start" icon={save} />
                            Mentés
                        </IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton
                            expand="full"
                            shape="round"
                            routerLink="/my/templates"
                            routerDirection="forward"
                        >
                            <IonIcon slot="start" icon={documentText} />
                            Sablonjaim
                        </IonButton>
                    </IonCol>
                </IonRow>
                <br />
                <br />
                <br />
                <IonRow>
                    <IonCol>
                        <p>
                            Tisztelt Felhasználónk!
                            <br />
                            <br />A regisztráció törlésével az online
                            ügyfélszolgálaton tárolt minden adata és beküldött
                            bejelentése végérvényesen és visszavonhatatlanul
                            törlésre kerül.
                        </p>
                        <IonItem lines="none">
                            <IonCheckbox
                                checked={deleteUserCheck}
                                onIonChange={(e) => {
                                    setDeleteUserCheck(e.detail.checked);
                                }}
                            />
                            <IonLabel>A fentieket tudomásul vettem.</IonLabel>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <IonButton
                            expand="full"
                            shape="round"
                            color="danger"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!deleteUserCheck) {
                                    setShowDeleteUserMissingCheck(true);
                                    return;
                                }
                                setShowDeleteUserAlert(true);
                            }}
                        >
                            Felhasználó törlése
                        </IonButton>
                    </IonCol>
                </IonRow>
            </form>
            <IonAlert
                header="Felhasználó törlése"
                message="Biztosan törölni szeretné a felhasználóját? Ez a folyamat visszavonhatatlan."
                isOpen={showDeleteUserAlert}
                buttons={[
                    {
                        text: "Törlés",
                        role: "ok",
                        handler: () => {
                            handleDeleteUser();
                        },
                    },
                    { text: "Mégse", role: "cancel" },
                ]}
                onDidDismiss={() => setShowDeleteUserAlert(false)}
            ></IonAlert>
            <IonAlert
                message="Felhasználó törlése előtt el kell fogadnia a törlés következményeit!"
                isOpen={showDeleteUserMissingCheck}
                buttons={[
                    {
                        text: "Rendben",
                        role: "ok",
                    },
                ]}
                onDidDismiss={() => setShowDeleteUserMissingCheck(false)}
            ></IonAlert>
        </PageWrapper>
    );
};
export default SettingsPage;
