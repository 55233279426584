import { IonIcon, IonButton } from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { eyeOutline, eye } from "ionicons/icons";

export default function GetPasswordShowIcon(
    showPassword: boolean,
    setShowPassword: Dispatch<SetStateAction<Boolean>>
) {
    return (
        <IonButton
            onClick={() => setShowPassword((prevState) => !prevState)}
            className="password-show-icon"
        >
            <IonIcon
                icon={showPassword ? eyeOutline : eye}
                slot="icon-only"
            ></IonIcon>
        </IonButton>
    );
}
