import { IonCard, IonCol, IonRouterLink, IonRow, IonSlide, IonSlides, IonSpinner, isPlatform } from "@ionic/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import useLoading from "../hooks/useLoading";
import "./News.scss";
import SkeletonNews from "./SkeletonNews";

import { Capacitor } from '@capacitor/core';

import iosDownload from '../assets/images/ios-download.png';
import androidDownload from '../assets/images/android-download.png';
import mobileMockup from '../assets/images/mockup.png';

const News: React.FC = () => {
    const { token } = useAuth();
    const [news, setNews] = useState([]);
    const [newsDesktop, setNewsDesktop] = useState([]);
    const { removeLoading } = useLoading();
    const isDesktop = !Capacitor.isNativePlatform();

    useEffect(() => {
        Axios.get("/posts", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                let tempNews = [];
                Object.keys(res.data.posts).forEach((i) => {
                    tempNews.push({
                        title: res.data.posts[i].cim,
                        preview:  res.data.posts[i].elozetes,
                        id: res.data.posts[i].hirid,
                        cardStyle: {
                            backgroundImage: `url(data:image/png;base64,${res.data.posts[i].featured_image})`,
                        },
                        gyartdatum: res.data.posts[i].gyartdatum,
                    });
                });
                setNews(tempNews);
                setNewsDesktop(tempNews);
            })
            .catch((err) => {
                removeLoading();
            });
    }, []);

    let items = [];
    if (news.length) {
        /*if (isDesktop) {
            let rows = [];
            let tempArrayForRows = new Array(
                Math.floor(news.length / 2) + (news.length % 2 > 0 ? 1 : 0)
            );
            for (let i = 0; i < tempArrayForRows.length; i++) {
                const firstCard = (i + 1) * 2 - 2;
                const secondCard = (i + 1) * 2 - 1;
                rows.push(
                    <IonRow key={i}>
                        <IonCol>
                            <IonCard
                                routerLink={`/news/${news[firstCard].id}`}
                                routerDirection="forward"
                                button={true}
                            >
                                <div
                                    style={news[firstCard].cardStyle}
                                    className="news-card"
                                >
                                    <div className="card-date">
                                        {news[firstCard].gyartdatum}
                                    </div>
                                    <div className="card-title">
                                        {news[firstCard].title}
                                    </div>
                                </div>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            {news.length > secondCard ? (
                                <IonCard
                                    routerLink={`/news/${news[secondCard].id}`}
                                    routerDirection="forward"
                                    button={true}
                                >
                                    <div
                                        style={news[secondCard].cardStyle}
                                        className="news-card"
                                    >
                                        <div className="card-date">
                                            {news[secondCard].gyartdatum}
                                        </div>
                                        <div className="card-title">
                                            {news[secondCard].title}
                                        </div>
                                    </div>
                                </IonCard>
                            ) : null}
                        </IonCol>
                    </IonRow>
                );
            }
            items.push(<div key={0}>{rows}</div>);
        } else {
            news.map((x) =>
                items.push(
                    <IonCard
                        key={x.id}
                        routerLink={`/news/${x.id}`}
                        routerDirection="forward"
                        button={true}
                    >
                        <div style={x.cardStyle} className="news-card">
                            <div className="card-date">{x.gyartdatum}</div>
                            <div className="card-title">{x.title}</div>
                        </div>
                    </IonCard>
                )
            );
        }*/
        news.map((x) =>
            items.push(
                <IonSlide key={x.id} className="newsSlide">
                    <div style={x.cardStyle} className="newsSlideImg"></div>
                    <IonRouterLink routerLink={`/news/${x.id}`} routerDirection="forward">
                        <div className="newsSlideBottom">
                            <div className="newsSlideTitle">{x.title}</div>
                            <div className="newsSlideDesc" dangerouslySetInnerHTML={{__html: x.preview}}></div>

                            <div  className="newsSlideButton">
                                <IonRouterLink routerLink={`/news/${x.id}`} routerDirection="forward" className="newsSlideLink">Tovább olvasom...</IonRouterLink>
                                <div className="newsSlideDate">{x.gyartdatum}</div>
                            </div>
                        </div>
                    </IonRouterLink>
                </IonSlide>
            )
        );
    }

    let itemsDesktop = [];
    if (newsDesktop.length) {
        newsDesktop.map((x) =>
            itemsDesktop.push(
                <IonCol key={x.id} size="12" size-md="6">
                    <IonCard
                        key={x.id}
                        routerLink={`/news/${x.id}`}
                        routerDirection="forward"
                        button={true}
                    >
                        <div style={x.cardStyle} className="news-card">
                            <div className="card-date">{x.gyartdatum}</div>
                            <div className="card-title">{x.title}</div>
                        </div>
                    </IonCard>
                </IonCol>
            )
        );
    }

    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        autoplay: {
            delay: 5000,
        },
    };

    return (
        <div className="news">
            {!isDesktop &&
                <>
                    {news.length > 0 ? (
                        <IonSlides pager={true} options={slideOpts}>
                            {items}
                        </IonSlides>
                    ) : (
                        <div className="spinnerCenter">
                            <IonSpinner />
                        </div>
                    )}
                </>
            }

            <div className="appDownloadBox">
                <img src={mobileMockup} alt="Alkalmazás" className="appImg" />
                <h4>A kényelmes használat érdekében töltse le alkalmazásunkat az alábbi linkeken:</h4>
                <div className="downloadButtons">
                    <a href="#" id="iosDownload">
                        <img src={iosDownload} alt="Alkalmazás letöltése az App Store-ból" />
                    </a>
                    <a href="#" id="androidDownload">
                        <img src={androidDownload} alt="Alkalmazás letöltése a Google Play Áruházból" />
                    </a>
                </div>
            </div>

            {isDesktop &&
                <>
                    {newsDesktop.length > 0 ? (
                        <IonRow>
                            {itemsDesktop}
                        </IonRow>
                    ) : (
                        <>
                            <SkeletonNews count={4} />
                        </>
                    )}
                </>
            }
        </div>
    );
};
export default News;
