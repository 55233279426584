import React from "react";
import PageWrapper from "../../layout/PageWrapper";
import { IonButton, IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { useParams } from "react-router";

const SuccessReportPage: React.FC = () => {
    const params = useParams();

    return (
        <PageWrapper>
            <h1 className="page-title">Sikeres bejelentés</h1>
            {params["type"] === "meter"
                ? "A mérőállás "
                : "Az ügyfélszolgálati "}
            bejelentés sikerült
            <br />
            <IonButton expand="block" size="large" routerLink="/my/dashboard">
                <IonIcon icon={chevronBack} />
                Vissza a főoldalra
            </IonButton>
        </PageWrapper>
    );
};
export default SuccessReportPage;
