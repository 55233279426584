import FormElement from "../../models/FormElement";

export const EmailElement: FormElement = {
    keyname: "email",
    type: "input",
    elementConfig: {
        name: "email",
        type: "email",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "E-mail cím",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};
export const PasswordElement: FormElement = {
    keyname: "password",
    type: "input",
    elementConfig: {
        name: "password",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Jelszó",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};

export const NameElement: FormElement = {
    keyname: "name",
    type: "input",
    elementConfig: {
        name: "name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Név",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};
export const PasswordConfirmElement: FormElement = {
    keyname: "password_confirmation",
    type: "input",
    elementConfig: {
        name: "password_confirmation",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Jelszó megerősítés",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};
export const PrivacyCheckElement: FormElement = {
    keyname: "privacy",
    type: "checkbox",
    elementConfig: {
        name: "privacy",
    },
    labelConfig: { className: "ion-text-wrap" },
    labelText: "Az adatvédelmi tájékoztatót elfogadom",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező elfogadni",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
        lines: "none",
    },
};
