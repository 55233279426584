import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonLabel,
    IonTitle,
    IonToolbar,
    isPlatform
} from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import logo from "../assets/images/pvizlogo.svg";
import { useAuth } from "../context/AuthContext";

const Header: React.FC = () => {
    const { loggedIn, setLogout } = useAuth();
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);
    const isDesktop = isPlatform("desktop");

    const openLogoutAlert = () => {
        setShowLogoutAlert(true);
    };

    useEffect(() => {
        if (showLogoutAlert) {
        }
    }, [showLogoutAlert]);

    return (
        <>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    {window.location.pathname !== "/my/dashboard" && window.location.pathname !== "/news" && (
                        <IonButtons slot="start">
                            <IonBackButton text="" />
                        </IonButtons>
                    )}
                    <IonTitle>
                        {isDesktop
                        ?
                            <a href="https://www.pannon-viz.hu/">
                                <img src={logo} alt="Logo" />
                            </a>
                        :
                            <img src={logo} alt="Logo" />
                        }
                            
                    </IonTitle>
                    {loggedIn && (
                        <IonButtons slot="end">
                            <IonButton onClick={openLogoutAlert}>
                                <IonIcon
                                    slot={isDesktop ? "start" : "icon-only"}
                                    icon={logOutOutline}
                                />
                                {isDesktop &&
                                    <IonLabel>Kijelentkezés</IonLabel>
                                }
                            </IonButton>
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonAlert
                header="Kijelentkezés"
                message="Biztosan ki szeretne lépni?"
                isOpen={showLogoutAlert}
                buttons={[
                    { text: "Mégse", role: "cancel" },
                    { text: "Kilépés", role: "ok", handler: setLogout },
                ]}
                onDidDismiss={() => setShowLogoutAlert(false)}
            ></IonAlert>
        </>
    );
};

export default Header;
