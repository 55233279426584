import React, { useState } from "react";
import {
    IonCard,
    IonCol,
    IonRow,
    IonSkeletonText,
    isPlatform,
} from "@ionic/react";

interface Props {
    count: number;
}

const SkeletonNews: React.FC<Props> = (props) => {
    const { count } = props;
    const isDesktop = isPlatform("desktop");

    const getItems = () => {
        let items = [];
        if (!isDesktop) {
            let i = 0;
            while (i < count) {
                i++;
                items.push(
                    <IonCard key={i}>
                        <div className="news-card skeleton">
                            <div className="card-date">
                                <IonSkeletonText
                                    animated
                                    style={{ width: "122px" }}
                                />
                            </div>
                            <div className="card-title">
                                <IonSkeletonText
                                    animated
                                    style={{ width: "175px", height: "21px" }}
                                />
                            </div>
                        </div>
                    </IonCard>
                );
            }
        } else {
            let rows = [];
            for (let i = 0; i < 3; i++) {
                rows.push(
                    <IonRow key={i}>
                        <IonCol>
                            <IonCard>
                                <div className="news-card skeleton">
                                    <div className="card-date">
                                        <IonSkeletonText
                                            animated
                                            style={{ width: "122px" }}
                                        />
                                    </div>
                                    <div className="card-title">
                                        <IonSkeletonText
                                            animated
                                            style={{
                                                width: "175px",
                                                height: "21px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </IonCard>
                        </IonCol>
                        <IonCol>
                            <IonCard>
                                <div className="news-card skeleton">
                                    <div className="card-date">
                                        <IonSkeletonText
                                            animated
                                            style={{ width: "122px" }}
                                        />
                                    </div>
                                    <div className="card-title">
                                        <IonSkeletonText
                                            animated
                                            style={{
                                                width: "175px",
                                                height: "21px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                );
            }
            items.push(
                <div key={0}>
                    {rows}
                </div>
            );
        }

        return items;
    };

    return <>{getItems()}</>;
};

export default SkeletonNews;
