import FormElement from "../../models/FormElement";

export default function SetFormValues(elements: FormElement[], values) {
    const tempElements = [...elements];
    if (values) {
        const valueArray = Object.entries(values);
        for (let i = 0; i < tempElements.length; i++) {
            for (const [vKey, vValue] of valueArray) {
                if (tempElements[i].keyname === "email" && values?.email_change_requests && values?.email_change_requests?.length > 0) {
                    let tempElement = { ...tempElements[i] };
                    tempElement.hintMessage = `Az e-mail cím módosítása megerősítést igényel. A megerősítő e-mailt a(z) ${values?.email_change_requests[0].email} címre küldtük.`;
                    tempElements[i] = tempElement;
                }

                if (vKey === tempElements[i].elementConfig.name) {
                    let tempElement = { ...tempElements[i] };
                    tempElement.value = vValue ?? "";
                    tempElements[i] = tempElement;
                }
            }
        }
    }
    return tempElements;
}
