import React from "react";
import PageWrapper from "../layout/PageWrapper";
import News from "../components/News";

const NewsPage: React.FC = () => {
    return (
        <PageWrapper hasHeader={true}>
            {/*<img src={logo} alt="Logo" className="pvizNewsLogo" />*/}
            <News />
            {/*<IonButton className="large-btn" expand="full" shape="round" routerLink="/my/send-meter-report" routerDirection="none">
                <IonIcon slot="start" icon={speedometer} />
                Mérőállás bejelentés
            </IonButton>*/}
        </PageWrapper>
    );
};
export default NewsPage;
