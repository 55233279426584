import FormElement from "../models/FormElement";
import {
    MeterSerialElement,
    MeterPositionElement,
    MeterDateElement,
    MeterNameElement,
    MeterPickerOrCaptureElement,
} from "../helpers/form/MeterReadingElements";

export default function GetMeterCardInputs(
    isTemplate: boolean,
    number: number
): FormElement[] {
    let TempMeterSerialElement = { ...MeterSerialElement };
    TempMeterSerialElement.keyname = MeterSerialElement.keyname + "_" + number;
    let TempMeterNameElement = { ...MeterNameElement };
    TempMeterNameElement.keyname = MeterNameElement.keyname + "_" + number;
    if (isTemplate) {
        TempMeterSerialElement.labelText = MeterSerialElement.labelText.replace(
            " *",
            ""
        );
        TempMeterNameElement.labelText = MeterNameElement.labelText.replace(
            " *",
            ""
        );
        TempMeterSerialElement.validity = null;
        TempMeterNameElement.validity = null;
        return [TempMeterNameElement, TempMeterSerialElement];
    } else {
        let TempMeterPositionElement = { ...MeterPositionElement };
        TempMeterPositionElement.keyname =
            MeterPositionElement.keyname + "_" + number;
        let TempMeterDateElement = { ...MeterDateElement };
        TempMeterDateElement.keyname = MeterDateElement.keyname + "_" + number;
        let TempMeterPickerOrCaptureElement = {
            ...MeterPickerOrCaptureElement,
        };
        TempMeterPickerOrCaptureElement.keyname =
            MeterPickerOrCaptureElement.keyname + "_" + number;
        return [
            TempMeterNameElement,
            TempMeterSerialElement,
            TempMeterPositionElement,
            TempMeterDateElement,
            TempMeterPickerOrCaptureElement,
        ];
    }
}
