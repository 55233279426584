import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import {
    IonButton,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import {
    FromElement,
    ToElement,
    TypeElement,
} from "../../helpers/form/EarlierServiceReportsElements";
import Input from "../../helpers/form/FormItem";
import { changedHandler } from "../../helpers/Validation";
import Axios from "axios";
import { GetFormValues } from "../../helpers/form/GetFormValues";
import { useAuth } from "../../context/AuthContext";
import useLoading from "../../hooks/useLoading";
import "./EarlierServiceReportsPage.scss";
import dayjs from "dayjs";
import { chevronDown, chevronUp } from "ionicons/icons";

const EarlierServiceReportsPage: React.FC = () => {
    const { token } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const [earlierReports, setEarlierReports] = useState([]);

    let FromDate = new Date();
    FromDate.setDate(0); // 0 will result in the last day of the previous month
    FromDate.setDate(1); // 1 will result in the first day of the month
    let TempFromElement = { ...FromElement };
    TempFromElement.value = dayjs(FromDate).format("YYYY-MM-DD");
    const ToDate = new Date();
    let TempToElement = { ...ToElement };
    TempToElement.value = dayjs(ToDate).format("YYYY-MM-DD");
    const [inputData, setInputData] = useState([
        TempFromElement,
        TempToElement,
        TypeElement,
    ]);

    useEffect(() => {
        Axios.get("/get-service-report-types", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                let tempInputData = [...inputData];
                let statementSelect = { ...tempInputData[2] };
                let options = [];
                for (const [key, value] of Object.entries(res.data.types)) {
                    options.push({ value: key, name: value });
                }
                statementSelect.elementConfig.options = options;
                tempInputData[2] = statementSelect;
                setInputData(tempInputData);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    }, []);

    const handleSearch = () => {
        addLoading();
        const values = GetFormValues(inputData);
        if (values["type_id"] === "-1") {
            delete values["type_id"];
        }
        Axios.get("/my-service-reports", {
            headers: { Authorization: `Bearer ${token}` },
            params: values,
        })
            .then((res) => {
                let tempReports = [];
                res.data.reports.map((report) => {
                    tempReports.push({
                        date: report.created_at,
                        showItems: false,
                        data: {
                            "Felhasználó név": report.customer_name,
                            "Felhasználó azonosító": report.customer_id,
                            "Felhasználási hely címe":
                                report.place_of_consumption,
                            "Bejelentés típusa": report.type.type,
                            Megjegyzés: report.comment,
                        },
                    });
                });
                setEarlierReports(tempReports);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    };

    const handleItemDataShow = (item, index) => {
        let tempItem = { ...item };
        tempItem.showItems = !tempItem.showItems;
        let tempEarlierReports = [...earlierReports];
        tempEarlierReports[index] = tempItem;
        setEarlierReports(tempEarlierReports);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    function getItems(reportItem, index) {
        const dateStr = dayjs(reportItem.date).format("YYYY-MM-DD");
        return (
            <div key={index}>
                <IonItem
                    className="item-stable ion-no-padding ion-no-margin"
                    onClick={() => handleItemDataShow(reportItem, index)}
                >
                    <IonLabel>
                        <h2>{reportItem.data["Felhasználó azonosító"]}</h2>
                        <p>{dateStr}</p>
                    </IonLabel>
                    <IonIcon
                        slot="end"
                        icon={reportItem.showItems ? chevronUp : chevronDown}
                    />
                </IonItem>
                {getHiddenItems(reportItem)}
            </div>
        );
    }
    function getHiddenItems(reportItem) {
        let hiddenItems = [];
        if (reportItem.showItems) {
            Object.keys(reportItem.data).map((item, i) =>
                hiddenItems.push(
                    <IonItem key={i} className="textItem">
                        <IonLabel position="stacked">{item}</IonLabel>
                        <p>{reportItem.data[item]}</p>
                    </IonItem>
                )
            );
        }
        return <div>{hiddenItems}</div>;
    }

    return (
        <PageWrapper>
            <h1 className="page-title">Korábbi bejelentéseim</h1>

            <form className="form" onSubmit={handleSubmit}>
                <IonRow>
                    {inputData.map((element, index) => (
                        <IonCol size="6" key={index}>
                            <Input
                                formElement={element}
                                changed={(event) =>
                                    changedHandler(
                                        index.toString(),
                                        inputData,
                                        setInputData,
                                        event
                                    )
                                }
                            />
                        </IonCol>
                    ))}
                    <IonCol size="12">
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={handleSearch}
                        >
                            Keresés
                        </IonButton>
                    </IonCol>
                </IonRow>
            </form>

            {earlierReports.length > 0 && (
                <>
                    <hr />
                    <IonList>
                        {earlierReports.map((report, i) => getItems(report, i))}
                    </IonList>
                </>
            )}
        </PageWrapper>
    );
};
export default EarlierServiceReportsPage;
