import React from "react";
import { IonLoading } from "@ionic/react";
import useLoading from "../hooks/useLoading";

export default function Loading() {
    const { loading } = useLoading();

    if (!loading) {
        return null;
    }
    return (
        <IonLoading
            isOpen={loading}
            message="Kérem várjon..."
        />
    );
}
