import React from "react";
import { IonButton } from "@ionic/react";
import PageWrapper from "../layout/PageWrapper";
import { useAuth } from "../context/AuthContext";
import { Capacitor } from "@capacitor/core";

const NotFoundPage: React.FC = () => {
    const { loggedIn } = useAuth();
    return (
        <PageWrapper>
            <h1 className="ion-text-center">Az oldal nem található.</h1>
            <br />
            <IonButton
                size="large"
                expand="block"
                shape="round"
                color="secondary"
                className="large-btn"
                routerLink={
                    !Capacitor.isNative || loggedIn ? "/my/dashboard" : "/news"
                }
                routerDirection="root"
            >
                Vissza a{" "}
                {!Capacitor.isNative || loggedIn ? "főoldalra" : "hírekre"}
            </IonButton>
        </PageWrapper>
    );
};

export default NotFoundPage;
