import React, { useEffect, useState } from "react";
import { IonButton } from "@ionic/react";
import { Redirect, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
    EmailElement,
    PinElement,
    NewPasswordElement,
    ConfirmNewPasswordElement,
} from "../helpers/form/PasswordRecoveryElements";
import PageWrapper from "../layout/PageWrapper";
import { changedHandler } from "../helpers/Validation";
import Input from "../helpers/form/FormItem";
import { CheckValidation, GetFormValues } from "../helpers/form/GetFormValues";
import Axios from "axios";
import useLoading from "../hooks/useLoading";
import SetPasswordShowIcon from "../helpers/form/SetPasswordShowIcon";
import { Capacitor } from "@capacitor/core";

interface Props {
    onLogin: (login: boolean, token: string) => void;
}

const PasswordRecoveryPage: React.FC<Props> = ({ onLogin }) => {
    const { loggedIn } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const params = useParams();

    let tempEmailElement = { ...EmailElement };
    tempEmailElement.value = params["email"];
    const [inputData, setInputData] = useState([
        tempEmailElement,
        PinElement,
        SetPasswordShowIcon(
            NewPasswordElement,
            showNewPassword,
            setShowNewPassword
        ),
        SetPasswordShowIcon(
            ConfirmNewPasswordElement,
            showConfirmPassword,
            setShowConfirmPassword
        ),
    ]);

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[2] = SetPasswordShowIcon(
            TempInputData[2],
            showNewPassword,
            setShowNewPassword
        );
        setInputData(TempInputData);
    }, [showNewPassword]);
    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[3] = SetPasswordShowIcon(
            TempInputData[3],
            showConfirmPassword,
            setShowConfirmPassword
        );
        setInputData(TempInputData);
    }, [showConfirmPassword]);

    useEffect(() => {
        if (
            !inputData[3].validity ||
            inputData[3].validity.length !== 1 ||
            inputData[2].value != inputData[3].validity[0].value
        ) {
            let tempInputData = [...inputData];
            let tempPasswordConfirm = { ...tempInputData[3] };
            tempPasswordConfirm.validity = [
                {
                    type: "equal",
                    value: inputData[2].value,
                    message: "A két jelszó nem egyezik",
                },
            ];
            tempInputData[3] = tempPasswordConfirm;
            setInputData(tempInputData);
        }
    }, [inputData]);

    const handlePasswordRecovery = () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            const values = GetFormValues(inputData);
            Axios.post("/password-recovery", values)
                .then((res) => {
                    if (Capacitor.isNative) {
                        window.location.replace("/news");
                    } else {
                        window.location.replace("/my/dashboard");
                    }
                    onLogin(true, res.data.access_token);
                    removeLoading();
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            setInputData(ValidObj["elements"]);
            removeLoading();
        }
    };

    return (
        <PageWrapper gridClassName="passwordrecovery-page-grid">
            <h1 className="pageTitle">Új jelszó</h1>

            <p>
                A megadott e-mail címre elküldtük a jelszó változtatáshoz
                szükséges PIN kódot.
            </p>

            <form className="form">
                {inputData.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputData,
                                setInputData,
                                event
                            )
                        }
                    />
                ))}

                <div className="form">
                    <div className="ion-text-center">
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={handlePasswordRecovery}
                        >
                            Jelszó megváltoztatása
                        </IonButton>
                    </div>
                </div>
            </form>
        </PageWrapper>
    );
};

export default PasswordRecoveryPage;
