export function scrollTo(selector: string) {
    /* A setTimeout a state frissítés miatt kelett. */
    setTimeout(function(){ 
        var selected = document.querySelectorAll(selector);
        var firstSelected = null;

        if(selected.length >= 1) {
            firstSelected = selected[0];
        }

        if(firstSelected !== null && firstSelected.offsetParent) {
            document.querySelector('ion-content').scrollToPoint(0,firstSelected.offsetParent.offsetTop - 50,150);
        }
    }, 75);
}
