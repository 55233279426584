import React, { useState } from "react";
import { IonPage, IonContent, IonGrid, isPlatform } from "@ionic/react";
import Header from "./Header";

type Props = {
    children: any;
    className?: string;
    gridClassName?: string;
    hasHeader?: boolean;
};

const PageWrapper: React.FC<Props> = ({ children, className, gridClassName, hasHeader = true }) => {
    const isDesktop = isPlatform("desktop");

    let pageClass = className ?? "" + " page";
    return (
        <IonPage className={pageClass}>
            {(hasHeader || isDesktop) &&
                <Header />
            }
            <IonContent>
                <IonGrid fixed className={gridClassName}>
                    <div className="page-content ion-padding">{children}</div>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PageWrapper;
