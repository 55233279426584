import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import { useAuth } from "../../context/AuthContext";
import { list } from "ionicons/icons";
import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { documentText } from "ionicons/icons";
import { TemplateElement, ConsumerIDElement, CommentElement, TemplateNameElement, PlaceOfConsumptionElement, CustomerNameElement } from "../../helpers/form/MeterReadingElements";
import Input from "../../helpers/form/FormItem";
import { changedHandler } from "../../helpers/Validation";
import Axios from "axios";
import useLoading from "../../hooks/useLoading";
import { CheckValidation, GetFormValues } from "../../helpers/form/GetFormValues";
import FormElement from "../../models/FormElement";
import GetMeterCardInputs from "../../helpers/GetMeterCardInputs";
import SetFormValues from "../../helpers/form/SetFormValues";

import { scrollTo } from "../../helpers/Scroller";

import "./MeterReadingPage.scss";
import { Redirect } from "react-router";
import ClearFormValues from "../../helpers/form/ClearFormValues";

const MeterReadingPage: React.FC = () => {
  const { addLoading, removeLoading } = useLoading();
  const { token } = useAuth();
  const [meterCount, setMeterCount] = useState(1);
  const [reportComplete, setReportComplete] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [inputDataFirst, setInputDataFirst] = useState([TemplateElement, CustomerNameElement, PlaceOfConsumptionElement, ConsumerIDElement]);
  const [inputData, setInputData] = useState([GetMeterCardInputs(false, 0)]);
  const [inputDataLast, setInputDataLast] = useState([CommentElement, TemplateNameElement]);

  useEffect(() => {
    if (token) {
      getTemplates();
    }
  }, [token]);
  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate === "-1") {
        let tempInputDataFirst = ClearFormValues(inputDataFirst);
        let tempTemplate = { ...tempInputDataFirst[0] };
        tempTemplate.value = "-1";
        let tempCustomerName = { ...tempInputDataFirst[1] };
        tempCustomerName.value = "";
        let tempPlaceOfConsumption = { ...tempInputDataFirst[2] };
        tempPlaceOfConsumption.value = "";
        let tempConsumerID = { ...tempInputDataFirst[3] };
        tempConsumerID.value = "";
        tempInputDataFirst[0] = tempTemplate;
        tempInputDataFirst[1] = tempCustomerName;
        tempInputDataFirst[2] = tempPlaceOfConsumption;
        tempInputDataFirst[3] = tempConsumerID;
        setInputDataFirst(tempInputDataFirst);
        let tempInputDataLast = ClearFormValues(inputDataLast);
        let tempTemplateName = { ...tempInputDataLast[1] };
        tempTemplateName.value = "Automatikus mentés";
        tempInputDataLast[1] = tempTemplateName;
        setInputDataLast(tempInputDataLast);

        let tempInputData = [];
        tempInputData.push(GetMeterCardInputs(false, 0));
        setInputData(tempInputData);
        setMeterCount(tempInputData.length);
        removeLoading();
      } else {
        Axios.get(`/report-template/show/${selectedTemplate}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            const loadedDataFirst = SetFormValues(inputDataFirst, res.data.template);
            setInputDataFirst(loadedDataFirst);

            const loadedDataLast = SetFormValues(inputDataLast, res.data.template);
            let tempTemplateName = { ...loadedDataLast[1] };
            tempTemplateName.value = res.data.template.name;
            loadedDataLast[1] = tempTemplateName;
            setInputDataLast(loadedDataLast);

            let tempInputData = [];
            if (res.data.template.meters.length) {
              for (let i = 0; i < res.data.template.meters.length; i++) {
                const meterCardData = GetMeterCardInputs(false, i);
                const loadedMeterCardData = SetFormValues(meterCardData, res.data.template.meters[i]);
                tempInputData.push(loadedMeterCardData);
              }
            } else {
              tempInputData.push(GetMeterCardInputs(false, 0));
            }
            setInputData(tempInputData);
            setMeterCount(tempInputData.length);

            removeLoading();
          })
          .catch((err) => {
            removeLoading();
          });
      }
    }
  }, [selectedTemplate]);
  useEffect(() => {
    if (inputDataFirst[0].value != selectedTemplate) {
      if (inputDataFirst[0].value) {
        addLoading();
      }
      setSelectedTemplate(inputDataFirst[0].value);
    }
  }, [inputDataFirst]);

  function getTemplates() {
    addLoading();
    Axios.get("/report-template/list", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempData = [...inputDataFirst];
        const tempTemplateElement = { ...tempData[0] };
        let options = [];
        options.push({
          value: "-1",
          name: "Üres űrlap",
        });
        let tempTemplateList = Object.entries(res.data.templates);
        tempTemplateList = tempTemplateList.sort((a, b) => (a[1]["name"] > b[1]["name"] ? 1 : b[1]["name"] > a[1]["name"] ? -1 : 0));
        tempTemplateList.forEach((element) => {
          options.push({
            name: element[1]["name"],
            value: element[1]["id"],
          });
        });
        if (tempTemplateList.length) {
          tempTemplateElement.value = tempTemplateList.reduce((a, b) => {
            return new Date(a[1]["last_used"]) > new Date(b[1]["last_used"]) ? a : b;
          })[1]["id"];
        } else {
          tempTemplateElement.value = "-1";
        }
        tempTemplateElement.elementConfig.options = options;
        tempTemplateElement.item.className = !tempTemplateList.length ? "hidden" : "";
        tempData[0] = tempTemplateElement;
        setInputDataFirst(tempData);
        setSelectedTemplate(tempTemplateElement.value);
        removeLoading();
      })
      .catch((err) => {
        removeLoading();
      });
  }

  useEffect(() => {
    if (meterCount > 1) {
      const cardId = "meterReadingCardId" + (meterCount - 1);
      let card = document.getElementById(cardId);
      // card.scrollIntoView();
    }
  }, [meterCount]);

  const updateMeterCount = (isPlus: boolean) => {
    let tempInputData = [...inputData];
    if (isPlus) {
      tempInputData.push(GetMeterCardInputs(false, meterCount));
    } else {
      tempInputData.pop();
    }
    setInputData(tempInputData);
    setMeterCount((prevState) => (isPlus ? prevState + 1 : prevState - 1));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const setMetersInputData = (modifiedInputData: FormElement[]) => {
    const findIndex = modifiedInputData[0].keyname.lastIndexOf("_");
    const index = parseInt(modifiedInputData[0].keyname.substring(findIndex + 1));

    const tempData = [...inputData];
    tempData[index] = modifiedInputData;
    setInputData(tempData);
  };

  const handleSave = async () => {
    addLoading();
    let metersValidity = [];
    for (let i = 0; i < inputData.length; i++) {
      metersValidity.push(CheckValidation(inputData[i]));
    }
    const isValidFirstPart = CheckValidation(inputDataFirst);
    const isValidLastPart = CheckValidation(inputDataLast);
    if (isValidFirstPart["isValid"] && isValidLastPart["isValid"] && metersValidity.every((x) => x["isValid"])) {
      const valuesLast = GetFormValues(inputDataLast);
      const valuesFirst = GetFormValues(inputDataFirst);
      const valuesMeters = [];
      for (let i = 0; i < inputData.length; i++) {
        let tempMeterData = GetFormValues(inputData[i]);
        const response = await fetch(tempMeterData[`img_${i}`]);
        const blob = await response.blob();
        tempMeterData[`img_${i}`] = blob;
        valuesMeters.push(GetFormValues(inputData[i]));
      }
      let values = { ...valuesFirst, ...valuesLast };
      values["meters"] = valuesMeters;
      if (values["template_id"] === "-1") {
        delete values["template_id"];
      }

      var form = new FormData();
      Object.keys(values).forEach((item, i) => {
        if (Array.isArray(values[item])) {
          for (let j = 0; j < values[item].length; j++) {
            Object.keys(values[item][j]).forEach((meterItem, k) => {
              form.append(item + "[" + j + "]" + "[" + meterItem + "]", values[item][j][meterItem]);
            });
          }
        } else {
          form.append(item, values[item]);
        }
      });

      Axios.post("/report-meters", form, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          removeLoading();
          setReportComplete(true);
        })
        .catch((err) => {
          removeLoading();
        });
    } else {
      if (!isValidFirstPart["isValid"]) {
        setInputDataFirst(isValidFirstPart["elements"]);
      }
      setInputDataLast(isValidLastPart["elements"]);
      if (!metersValidity.every((x) => x["isValid"])) {
        let tempInputData = [];
        for (let i = 0; i < metersValidity.length; i++) {
          tempInputData.push(metersValidity[i]["elements"]);
        }
        setInputData(tempInputData);
      }
      scrollTo(".errorMessage");
      removeLoading();
    }
  };

  if (reportComplete) {
    return <Redirect to="/my/report-complete/meter" />;
  }
  return (
    <PageWrapper>
      <h1 className="page-title">Mérőállás bejelentés</h1>
      <IonText color="danger">
        <p style={{ lineHeight: 1.6 }}>Mérőcsere esetén kérjük a gyári számot javítani szíveskedjék!</p>
      </IonText>
      <form className="form" onSubmit={handleSubmit}>
        {inputDataFirst.map((element, index) => (
          <Input key={index} formElement={element} changed={(event) => changedHandler(index.toString(), inputDataFirst, setInputDataFirst, event)} />
        ))}

        <br />
        <h3 className="meter-card-title">Mérőóra adatai</h3>
        {inputData.map((meterCardData, i) => (
          <div className="blue-form" key={i}>
            <div className="meter-reading-card" id={"meterReadingCardId" + i}>
              {meterCardData.map((element, j) => (
                <Input key={j} formElement={element} changed={(event) => changedHandler(j.toString(), meterCardData, setMetersInputData, event)} />
              ))}
            </div>
          </div>
        ))}

        <IonRow>
          {meterCount > 1 ? (
            <IonCol size="6">
              <IonButton expand="full" shape="round" color="secondary" onClick={() => updateMeterCount(false)}>
                Törlés
              </IonButton>
            </IonCol>
          ) : null}
          <IonCol size={meterCount > 1 ? "6" : "12"}>
            <IonButton expand="full" shape="round" color="secondary" onClick={() => updateMeterCount(true)}>
              Új hozzáadása
            </IonButton>
          </IonCol>
        </IonRow>

        <br />
        {inputDataLast.map((element, index) => (
          <Input key={index} formElement={element} changed={(event) => changedHandler(index.toString(), inputDataLast, setInputDataLast, event)} />
        ))}

        <br />
        <IonButton expand="full" shape="round" className="large-btn" onClick={handleSave}>
          Mérőállás jelentése
        </IonButton>
        <br />
        <IonRow>
          <IonCol size="6">
            <IonButton expand="full" shape="round" routerLink="/my/templates" routerDirection="forward">
              <IonIcon slot="start" icon={documentText} />
              Sablonjaim
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton expand="full" shape="round" routerLink="/my/meter-reports" routerDirection="forward">
              <IonIcon slot="start" icon={list} />
              Korábbi
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </PageWrapper>
  );
};
export default MeterReadingPage;
