import React, { useState, useCallback } from "react";

export const ToastContext = React.createContext({
    toast: null,
    addToast: (message: string, duration: number) => {},
    removeToast: () => {},
});

export default function ToastProvider({ children }) {
    const [toast, setToast] = useState(null);

    const removeToast = () => setToast(null);

    const addToast = (message: string, duration: number) => setToast({ message, duration });

    const contextValue = {
        toast,
        addToast: useCallback((message: string, duration: number) => addToast(message, duration), []),
        removeToast: useCallback(() => removeToast(), []),
    };

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
        </ToastContext.Provider>
    );
}
