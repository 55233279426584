import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import { useAuth } from "../../context/AuthContext";
import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { documentText, list } from "ionicons/icons";
import { TemplateElement, StatementTypeElement, ConsumerIDElement, ConsumerNameElement, ConsumerAddressElement, StatementTextElement, TemplateNameElement } from "../../helpers/form/CustomerServiceElements";
import FormItem from "../../helpers/form/FormItem";
import { changedHandler } from "../../helpers/Validation";
import Axios from "axios";
import useLoading from "../../hooks/useLoading";
import { CheckValidation, GetFormValues } from "../../helpers/form/GetFormValues";
import SetFormValues from "../../helpers/form/SetFormValues";
import { scrollTo } from "../../helpers/Scroller";
import { Redirect } from "react-router";
import ClearFormValues from "../../helpers/form/ClearFormValues";
import FormElement from "../../models/FormElement";

const CustomerServicePage: React.FC = () => {
  const { token } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [reportComplete, setReportComplete] = useState(false);
  const [requiredUserData, setRequiredUserData] = useState(false);

  const [inputData, setInputData] = useState([TemplateElement, StatementTypeElement, ConsumerIDElement, ConsumerNameElement, ConsumerAddressElement, StatementTextElement, TemplateNameElement]);

  function GetRequiredUserData(data: FormElement[], required: boolean) {
    if (!required) {
      let tempCustomerID = { ...data[2] };
      let tempCustomerName = { ...data[3] };
      let tempPlaceOfConsumption = { ...data[4] };
      tempCustomerID.validity = null;
      tempCustomerName.validity = null;
      tempPlaceOfConsumption.validity = null;
      data[2] = tempCustomerID;
      data[3] = tempCustomerName;
      data[4] = tempPlaceOfConsumption;
    } else {
      const ReqValidity = [
        {
          type: "required",
          message: "Kötelező mező",
        },
      ];
      //let tempCustomerID = { ...data[2] };
      //tempCustomerID.validity = [...ReqValidity];
      let tempCustomerName = { ...data[3] };
      tempCustomerName.validity = [...ReqValidity];
      let tempPlaceOfConsumption = { ...data[4] };
      tempPlaceOfConsumption.validity = [...ReqValidity];
      //data[2] = tempCustomerID;
      data[3] = tempCustomerName;
      data[4] = tempPlaceOfConsumption;
    }
    return data;
  }

  useEffect(() => {
    if (inputData[0].value) {
      let tempInputData = [...inputData];
      GetRequiredUserData(tempInputData, requiredUserData);
      setInputData(tempInputData);
    }
  }, [requiredUserData]);
  useEffect(() => {
    addLoading();
    Axios.get("/get-service-report-types", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let tempInputData = [...inputData];
      let statementSelect = { ...tempInputData[1] };
      let options = [];
      for (const [key, value] of Object.entries(res.data.types)) {
        options.push({ value: key, name: value });
      }
      statementSelect.elementConfig.options = options;
      tempInputData[1] = statementSelect;
      Axios.get("/report-template/list", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          let tempTemplateElement = { ...tempInputData[0] };
          let options = [];
          options.push({
            value: "-1",
            name: "Üres űrlap",
          });
          let tempTemplateList = Object.entries(res.data.templates);
          tempTemplateList = tempTemplateList.sort((a, b) => (a[1]["name"] > b[1]["name"] ? 1 : b[1]["name"] > a[1]["name"] ? -1 : 0));
          tempTemplateList.forEach((element) => {
            options.push({
              name: element[1]["name"],
              value: element[1]["id"],
            });
          });
          if (tempTemplateList.length) {
            tempTemplateElement.value = tempTemplateList.reduce((a, b) => {
              return new Date(a[1]["last_used"]) > new Date(b[1]["last_used"]) ? a : b;
            })[1]["id"];
          } else {
            tempTemplateElement.value = "-1";
          }
          tempTemplateElement.item.className = !tempTemplateList.length ? "hidden" : "";
          tempTemplateElement.elementConfig.options = options;
          tempInputData[0] = tempTemplateElement;
          setInputData(tempInputData);
        })
        .catch((err) => {
          removeLoading();
        });
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSendReport = () => {
    addLoading();
    const ValidObj = CheckValidation(inputData);
    if (ValidObj["isValid"]) {
      const values = GetFormValues(inputData);
      if (values["template_id"] === "-1") {
        delete values["template_id"];
      }
      Axios.post("/send-service-report", values, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          removeLoading();
          setReportComplete(true);
        })
        .catch((err) => {
          removeLoading();
        });
    } else {
      setInputData(ValidObj["elements"]);
      scrollTo(".errorMessage");
      removeLoading();
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate === "-1") {
        let tempInputData = ClearFormValues(inputData);
        let tempTemplate = { ...tempInputData[0] };
        tempTemplate.value = "-1";
        let tempTemplateName = { ...tempInputData[6] };
        tempTemplateName.value = "Automatikus mentés";
        tempInputData[0] = tempTemplate;
        tempInputData[6] = tempTemplateName;
        setInputData(tempInputData);
        removeLoading();
      } else if (selectedTemplate !== "") {
        Axios.get(`/report-template/show/${inputData[0].value}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            let result = res.data.template;
            // bugos ez a szar, ezért kiveszem, úgyis kötelező (de már ott a megoldás, a leaveTheStateAlone prop a FormElement-be)
            // TODO - leaveTheStateAlone (végigvezetni)
            result.service_report_type_id = null;
            result.comment = "";
            let loadedData = SetFormValues(inputData, result);
            let tempTemplateName = { ...loadedData[6] };
            tempTemplateName.value = res.data.template.name;
            loadedData[6] = tempTemplateName;
            if ((loadedData[1].value === "1" || loadedData[1].value === "9" || loadedData[1].value === "10") && loadedData[2].validity) {
              loadedData = GetRequiredUserData(loadedData, false);
            } else if (loadedData[1].value !== "1" && loadedData[1].value !== "9" && loadedData[1].value !== "10" && !loadedData[2].validity) {
              loadedData = GetRequiredUserData(loadedData, true);
            }
            setInputData(loadedData);
            removeLoading();
          })
          .catch((err) => {
            removeLoading();
          });
      }
    }
  }, [selectedTemplate]);
  useEffect(() => {
    if (inputData[0].value !== selectedTemplate) {
      if (inputData[0].value) {
        addLoading();
      }
      setSelectedTemplate(inputData[0].value);
    }
    if ((inputData[1].value === "1" || inputData[1].value === "9" || inputData[1].value === "10") && inputData[2].validity) {
      setRequiredUserData(false);
    } else if (inputData[1].value && inputData[1].value !== "1" && inputData[1].value !== "9" && inputData[1].value !== "10" && !inputData[2].validity) {
      setRequiredUserData(true);
    }
  }, [inputData]);

  if (reportComplete) {
    return <Redirect to="/my/report-complete/service" />;
  }

  return (
    <PageWrapper>
      <h1 className="page-title">Ügyfélszolgálati bejelentés</h1>
      <IonText color="danger">
        <p style={{ lineHeight: 1.6 }}>
          Tájékoztatjuk, hogy a bejelentők feldolgozása a következő munkanapon történik!
          <br />
          Szolgáltatással kapcsolatos sürgős hibabejelentéshez hívja a 96/522-601-es telefonszámot.
        </p>
      </IonText>
      <form className="form" onSubmit={handleSubmit}>
        {inputData.map((element, index) => (
          <FormItem key={index} formElement={element} changed={(event) => changedHandler(index.toString(), inputData, setInputData, event)} />
        ))}

        <br />
        <IonButton expand="full" shape="round" className="large-btn" onClick={handleSendReport}>
          Bejelentés elküldése
        </IonButton>
        <IonRow>
          <IonCol size="6">
            <IonButton expand="full" shape="round" routerLink="/my/templates" routerDirection="forward">
              <IonIcon slot="start" icon={documentText} />
              Sablonjaim
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton expand="full" shape="round" routerLink="/my/service-reports" routerDirection="forward">
              <IonIcon slot="start" icon={list} />
              Korábbi
            </IonButton>
          </IonCol>
        </IonRow>
      </form>
    </PageWrapper>
  );
};
export default CustomerServicePage;
