import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import {
    IonButton,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import {
    FromElement,
    ToElement,
    FilterElement,
    TemplateElement,
} from "../../helpers/form/EarlierMeterReportsElements";
import Input from "../../helpers/form/FormItem";
import Axios from "axios";
import { GetFormValues } from "../../helpers/form/GetFormValues";
import { useAuth } from "../../context/AuthContext";
import useLoading from "../../hooks/useLoading";
import "./EarlierMeterReportsPage.scss";
import dayjs from "dayjs";
import { changedHandler } from "../../helpers/Validation";
import { chevronDown, chevronUp } from "ionicons/icons";
import { GetFormattedDate } from "../../helpers/DateHelper";

const EarlierMeterReportsPage: React.FC = () => {
    const { token } = useAuth();
    const { addLoading, removeLoading } = useLoading();
    const [earlierReports, setEarlierReports] = useState([]);

    let FromDate = new Date();
    FromDate.setDate(0); // 0 will result in the last day of the previous month
    FromDate.setDate(1); // 1 will result in the first day of the month
    let TempFromElement = { ...FromElement };
    TempFromElement.value = dayjs(FromDate).format("YYYY-MM-DD");
    const ToDate = new Date();
    let TempToElement = { ...ToElement };
    TempToElement.value = dayjs(ToDate).format("YYYY-MM-DD");
    const [inputData, setInputData] = useState([
        TempFromElement,
        TempToElement,
        FilterElement,
        TemplateElement,
    ]);

    useEffect(() => {
        Axios.get("/report-template/list", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                let tempInputData = [...inputData];
                let templateSelect = { ...tempInputData[3] };
                let options = [];
                let tempTemplateList = Object.entries(res.data.templates);
                tempTemplateList = tempTemplateList.sort((a, b) =>
                    a[1]["name"] > b[1]["name"]
                        ? 1
                        : b[1]["name"] > a[1]["name"]
                        ? -1
                        : 0
                );
                tempTemplateList.forEach((element) => {
                    options.push({
                        name: element[1]["name"],
                        value: element[1]["id"],
                    });
                });
                templateSelect.elementConfig.options = options;
                if (!options.length) {
                    templateSelect.item.className = "hidden";
                }
                tempInputData[3] = templateSelect;
                setInputData(tempInputData);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    }, []);

    useEffect(() => {
        if (inputData[2].value === "1" && inputData[3].item.className) {
            const tempInputData = [...inputData];
            let tempTypeSelect = { ...tempInputData[3] };
            tempTypeSelect.item.className = null;
            tempInputData[3] = tempTypeSelect;
            setInputData(tempInputData);
        }
        if (
            (inputData[2].value === "0" || inputData[2].value === "2") &&
            !inputData[3].item.className
        ) {
            const tempInputData = [...inputData];
            let tempTypeSelect = { ...tempInputData[3] };
            tempTypeSelect.item.className = "hidden";
            tempInputData[3] = tempTypeSelect;
            setInputData(tempInputData);
        }
    }, [inputData]);

    const handleSearch = () => {
        addLoading();
        const values = GetFormValues(inputData);
        if (values["type"] === "0") {
            delete values["template"];
        } else if (values["type"] === "2") {
            values["template"] = "-1";
        }
        Axios.get("/my-meter-reports", {
            headers: { Authorization: `Bearer ${token}` },
            params: values,
        })
            .then((res) => {
                let tempReports = [];
                res.data.reports.forEach((report: any) => {
                    let newReport = {
                        date: GetFormattedDate(new Date(report.created_at)),
                        showItems: false,
                        data: {
                            "Felhasználó azonosító": report.customer_id,
                            "Felhasználó név": report.customer_name,
                            "Felhasználási hely címe":
                                report.place_of_consumption,
                            Megjegyzés: report.comment ?? "",
                        },
                    };
                    report.readings.forEach((reading: any, index: any) => {
                        newReport.data[`${index + 1}. Mérőóra gyári száma`] =
                            reading.water_meter.meter_id;
                        if (reading.water_meter.meter_name) {
                            newReport.data[
                                `${index + 1}. Mérőóra megnevezése`
                            ] = reading.water_meter.meter_name;
                        }
                        newReport.data[`${index + 1}. Mérőóra állása`] =
                            reading.meter_reading;
                        newReport.data[
                            `${index + 1}. Mérőóra állás dátuma`
                        ] = GetFormattedDate(new Date(reading.report_time));
                    });
                    tempReports.push(newReport);
                });
                setEarlierReports(tempReports);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    };

    const handleItemDataShow = (item: any, index: any) => {
        let tempItem = { ...item };
        tempItem.showItems = !tempItem.showItems;
        let tempEarlierReports = [...earlierReports];
        tempEarlierReports[index] = tempItem;
        setEarlierReports(tempEarlierReports);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    function getItems(reportItem: any, index: any) {
        const dateStr = dayjs(reportItem.date).format("YYYY-MM-DD");
        return (
            <div key={index}>
                <IonItem
                    className="item-stable ion-no-padding ion-no-margin"
                    onClick={() => handleItemDataShow(reportItem, index)}
                >
                    <IonLabel>
                        <h2>{reportItem.data["Felhasználó azonosító"]}</h2>
                        <p>{dateStr}</p>
                    </IonLabel>
                    <IonIcon
                        slot="end"
                        icon={reportItem.showItems ? chevronUp : chevronDown}
                    />
                </IonItem>
                {getHiddenItems(reportItem)}
            </div>
        );
    }
    function getHiddenItems(reportItem) {
        let hiddenItems = [];
        if (reportItem.showItems) {
            Object.keys(reportItem.data).forEach((item: any, i: any) =>
                hiddenItems.push(
                    <IonItem key={i} className="textItem">
                        <IonLabel position="stacked">{item}</IonLabel>
                        <p>{reportItem.data[item]}</p>
                    </IonItem>
                )
            );
        }
        return <div>{hiddenItems}</div>;
    }

    return (
        <PageWrapper>
            <h1 className="page-title">Korábbi bejelentéseim</h1>

            <form className="form" onSubmit={handleSubmit}>
                <IonRow>
                    {inputData.map((element, index) => (
                        <IonCol size="6" key={index}>
                            <Input
                                formElement={element}
                                changed={(event) =>
                                    changedHandler(
                                        index.toString(),
                                        inputData,
                                        setInputData,
                                        event
                                    )
                                }
                            />
                        </IonCol>
                    ))}
                    <IonCol size="12">
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={handleSearch}
                        >
                            Keresés
                        </IonButton>
                    </IonCol>
                </IonRow>
            </form>

            <IonList>
                {earlierReports.map((report, i) => getItems(report, i))}
            </IonList>
        </PageWrapper>
    );
};
export default EarlierMeterReportsPage;
