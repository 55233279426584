import { IonItem, IonLabel, IonTextarea } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const Textarea: React.FC<Props> = ({ formElement, changed }) => {
    let labelElement = null;
    let inputElement = null;

    if (formElement.labelConfig || formElement.labelText) {
        labelElement = (
            <IonLabel {...formElement.labelConfig}>
                {formElement.labelText ?? ""}
            </IonLabel>
        );
    }
    inputElement = (
        <IonTextarea
            {...formElement.elementConfig}
            // onIonChange={changed}
            onIonInput={changed}
            onIonBlur={changed}
            value={formElement.value}
        />
    );

    let classname =
        formElement.item && formElement.item.className
            ? formElement.item.className
            : "";
    if (formElement.elementConfig && formElement.elementConfig.maxlength) {
        classname += " textarea-with-counter";
    }

    return (
        <IonItem className={classname}>
            {labelElement}
            {inputElement}
            {formElement.elementConfig.maxlength ? (
                <div className="textarea-counter">
                    {formElement.value.length +
                        "/" +
                        formElement.elementConfig.maxlength}
                </div>
            ) : null}
        </IonItem>
    );
};
export default Textarea;
