import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import Axios from "axios";
import useLoading from "../../hooks/useLoading";

const ContactsPage: React.FC = () => {
    const [contactData, setContactData] = useState("");
    const { addLoading, removeLoading } = useLoading();

    useEffect(() => {
        addLoading();
        Axios.get("/contact")
            .then((res) => {
                let tempContactData = res.data.contact;
                tempContactData = tempContactData.replace(
                    "<p>K&ouml;zponti faxsz&aacute;m: (96) 310-833</p>",
                    ""
                );
                tempContactData = tempContactData.replace(
                    "iktato@pannon-viz.hu",
                    "<a href='mailto:iktato@pannon-viz.hu'>iktato@pannon-viz.hu</a>"
                );
                tempContactData = tempContactData.replace(
                    "&Uuml;gyf&eacute;lszolg&aacute;lati irod&aacute;k&nbsp;oldalon",
                    "<a href='https://www.pannon-viz.hu/cikk/ugyfelszolgalatok.html'>&Uuml;gyf&eacute;lszolg&aacute;lati irod&aacute;k&nbsp;oldalon</a>"
                );
                setContactData(tempContactData);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    }, []);

    return (
        <PageWrapper>
            <div className="contacts-page">
                <h1 className="page-title">Kapcsolat</h1>
                <div dangerouslySetInnerHTML={{ __html: contactData }}></div>
            </div>
        </PageWrapper>
    );
};
export default ContactsPage;
