import { Dispatch, SetStateAction } from "react";
import FormElement from "../../models/FormElement";
import GetPasswordShowIcon from "../../components/PasswordShowIcon";

export default function SetPasswordShowIcon(
    PasswordElement: FormElement,
    showPassword: boolean,
    setShowPassword: Dispatch<SetStateAction<Boolean>>
) {
    let TempPasswordElement = { ...PasswordElement };
    TempPasswordElement.elementConfig.type = showPassword ? "text" : "password";
    TempPasswordElement.endIcon = GetPasswordShowIcon(
        showPassword,
        setShowPassword
    );
    return TempPasswordElement;
}
