import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import hu from "date-fns/locale/hu";
import React from "react";
import FormElement from "../../models/FormElement";
import { Capacitor } from "@capacitor/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
// import { green, yellow } from "@material-ui/core/colors";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const DatePicker: React.FC<Props> = ({ formElement, changed }) => {
    let labelElement = null;
    let inputElement = null;

    if (formElement.labelConfig || formElement.labelText) {
        labelElement = (
            <IonLabel {...formElement.labelConfig}>
                {formElement.labelText ?? ""}
            </IonLabel>
        );
    }

    if (Capacitor.isNative) {
        inputElement = (
            <IonDatetime
                {...formElement.elementConfig}
                onIonChange={changed}
                onIonBlur={changed}
                value={formElement.value}
                doneText="Kész"
                cancelText="Mégse"
                monthShortNames={[
                    "Jan",
                    "Feb",
                    "Már",
                    "Ápr",
                    "Máj",
                    "Jún",
                    "Júl",
                    "Aug",
                    "Szep",
                    "Okt",
                    "Nov",
                    "Dec",
                ]}
            />
        );
    } else {
        const defaultMaterialTheme = createMuiTheme({
            palette: {
                primary: {
                    light: "#3373b0",
                    main: "#00509D",
                    dark: "#00386d",
                    contrastText: "#ffffff",
                },
            },
        });
        const format = formElement.elementConfig.displayFormat
            ? formElement.elementConfig.displayFormat
                  .replace(/Y/g, "y")
                  .replace(/D/g, "d")
            : "yyyy.MM.dd";
        inputElement = (
            <ThemeProvider theme={defaultMaterialTheme}>
                <MuiPickersUtilsProvider locale={hu} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        minDateMessage="A dátum korábbi mint a minimum dátum"
                        maxDateMessage="A dátum későbbi mint a maximum dátum"
                        invalidDateMessage="Nem megfelelő formátum"
                        name={formElement.elementConfig.name}
                        format={format}
                        onChange={changed}
                        // onBlur={changed}
                        value={new Date(formElement.value)}
                        okLabel="Kész"
                        cancelLabel="Mégse"
                        onKeyDown={(event) => event.preventDefault()}
                    />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        );
    }

    return (
        <IonItem {...formElement.item}>
            {labelElement}
            {inputElement}
        </IonItem>
    );
};
export default DatePicker;
