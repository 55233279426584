import { Dispatch, SetStateAction } from "react";
import FormElement from "../models/FormElement";
import dayjs from "dayjs";
import { FromElement } from "./form/EarlierServiceReportsElements";

export function changedHandler(
    id: string,
    inputData: any,
    setInputData?: Dispatch<SetStateAction<FormElement[]>>,
    event?: any
) {
    const formCopy = [...inputData];
    const formElement = { ...formCopy[id] };
    if (setInputData && formElement.forcedClear) {
        formElement.forcedClear = false;
        formCopy[id] = formElement;
        setInputData(formCopy);
        return;
    }
    if (event) {
        switch (formElement.type) {
            case "input":
                if (formElement.value === event.target.value) {
                    return;
                }
                formElement.value = event.target.value;
                break;
            case "checkbox":
                if (formElement.value === (event.target.checked ? "1" : "0")) {
                    return;
                }
                formElement.value = event.target.checked ? "1" : "0";
                break;
            case "textarea":
                if (formElement.value === event.target.value) {
                    return;
                }
                formElement.value = event.target.value;
                break;
            case "select":
                if ((formElement.value ?? "") === (event.target.value ?? "")) {
                    return;
                }
                formElement.value = event.target.value;
                break;
            case "date":
                const eventDate = event.target ? event.target.value : event;
                if (
                    formElement.value === dayjs(eventDate).format("YYYY-MM-DD")
                ) {
                    return;
                }
                formElement.value = dayjs(eventDate).format("YYYY-MM-DD");
                break;
            case "imagepickerandcapture":
                formElement.value = event.target.value;
                break;
        }
    }
    // if (formElement.touched) {
    let validityResult = checkValidity(
        formElement.value,
        formElement.validity,
        formElement.type
    );
    formElement.valid = validityResult ? validityResult.isValid : true;
    if (validityResult) {
        formElement.validationErrorMessage = validityResult.message;
    }
    // }

    formElement.touched = true;
    formCopy[id] = formElement;

    // let isValid = true;

    // for (const id in formCopy) {
    //     isValid = formCopy[id].valid && isValid;
    // }
    if (setInputData) {
        setInputData(formCopy);
    } else {
        return formElement;
    }
}

const checkValidity = (value, rules, type) => {
    let result = { isValid: true, message: "" };

    if (!rules) {
        return result;
    }

    if (rules) {
        for (let i = 0; i < rules.length; i++) {
            if (!result.isValid) {
                break;
            }
            switch (rules[i].type) {
                case "required":
                    switch (type) {
                        case "input":
                            if (Number.isInteger(value)) {
                                result.isValid = value !== "";
                            } else {
                                result.isValid = value.trim() !== "";
                            }
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                        case "textarea":
                            if (Number.isInteger(value)) {
                                result.isValid = value !== "";
                            } else {
                                result.isValid = value.trim() !== "";
                            }
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                        case "checkbox":
                            result.isValid = value === "1";
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                        case "date":
                            result.isValid = value;
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                        case "select":
                            result.isValid = value;
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                    }
                    break;
                case "equal":
                    switch (type) {
                        case "input":
                            result.isValid = value === rules[i].value;
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                    }
                    break;
                case "maxlength":
                    switch (type) {
                        case "input":
                            result.isValid = value.length <= rules[i].value;
                            if (!result.isValid) {
                                result.message = rules[i].message;
                            }
                            break;
                    }
                    break;
            }
        }
    }
    return result;
};
