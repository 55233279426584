const OnlyNumbers = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "Backspace",
    "Delete",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
];
export const OnlyNumberEvent = (event: any) => {
    if (!OnlyNumbers.includes(event.key)) {
        event.preventDefault();
    }
};
