import React, { useState, useCallback } from "react";

export const LoadingContext = React.createContext({
    loading: false,
    addLoading: () => {},
    removeLoading: () => {},
});

export default function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);

    const removeLoading = () => setLoading(false);

    const addLoading = () => setLoading(true);

    const contextValue = {
        loading,
        addLoading: useCallback(() => addLoading(), []),
        removeLoading: useCallback(() => removeLoading(), []),
    };

    return (
        <LoadingContext.Provider value={contextValue}>
            {children}
        </LoadingContext.Provider>
    );
}
