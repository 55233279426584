import React from "react";
import { IonAlert } from "@ionic/react";
import useAlert from "../hooks/useAlert";
import "./Alert.css";

export default function Alert() {
    const { alert, removeAlert } = useAlert();

    let dismissHandler = () => {};

    const handleDismiss = () => {
        removeAlert();
        dismissHandler();
    };
    if (!alert || !alert.message) {
        return null;
    }
    if (alert.onDismissHandler) dismissHandler = alert.onDismissHandler;
    return (
        <IonAlert
            header={alert.title}
            isOpen={!!alert}
            message={alert.message}
            onDidDismiss={handleDismiss}
            buttons={["OK"]}
        />
    );
}
