import {
    IonIcon,
    IonTabButton,
    IonTabs,
    IonTabBar,
    IonRouterOutlet,
    IonLabel,
} from "@ionic/react";
import React from "react";
import { useAuth } from "./context/AuthContext";
import { Redirect, Route } from "react-router";
import DashboardPage from "./pages/protected/DashboardPage";
import MeterReadingPage from "./pages/protected/MeterReadingPage";
import CustomerServicePage from "./pages/protected/CustomerServicePage";
import SettingsPage from "./pages/protected/SettingsPage";
import ContactsPage from "./pages/protected/ContactsPage";
import TemplatesPage from "./pages/protected/TemplatesPage";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import LostPasswordPage from "./pages/LostPasswordPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import RegistrationVerifyPage from "./pages/RegistrationVerifyPage";
import NewsPage from "./pages/NewsPage";
import NewsDataPage from "./pages/NewsDataPage";
import SuccessReportPage from "./pages/protected/SuccessReportPage";
import { IonReactRouter } from "@ionic/react-router";

import {
    person,
    mailOpen,
    speedometer,
    home,
    documentText,
} from "ionicons/icons";
import NotFoundPage from "./pages/NotFoundPage";
import EarlierReportsPage from "./pages/protected/EarlierServiceReportsPage";
import EarlierMeterReportsPage from "./pages/protected/EarlierMeterReportsPage";
import { Capacitor } from "@capacitor/core";

const AppTabs: React.FC = () => {
    const { loggedIn, userInfo, setLogout, setAuth } = useAuth();
    
    const isDesktop = !Capacitor.isNativePlatform();
    const MeterReadingUrl = "/my/send-meter-report";
    const CustomerServiceReportUrl = "/my/send-service-report";
    const DashboardUrl = "/my/dashboard";
    const NewsUrl = "/news";
    const Settings = "/my/settings";
    const ContactsUrl = "/contacts";

    return (
        <>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet animated={true}>
                        <Route exact path="/login">
                            <LoginPage
                                onLogin={(login, token) =>
                                    setAuth(login, token)
                                }
                            />
                        </Route>
                        <Route exact path="/registration">
                            <RegistrationPage
                                onRegistration={(login, token) =>
                                    setAuth(login, token)
                                }
                            />
                        </Route>
                        <Route exact path="/lost-password">
                            <LostPasswordPage />
                        </Route>
                        <Route path="/password-recovery/:email">
                            <PasswordRecoveryPage
                                onLogin={(login, token) =>
                                    setAuth(login, token)
                                }
                            />
                        </Route>
                        <Route exact path="/verify">
                            <RegistrationVerifyPage />
                        </Route>
                        <Route exact path={NewsUrl}>
                            <NewsPage />
                        </Route>
                        <Route exact path="/news/:id">
                            <NewsDataPage />
                        </Route>
                        <Route exact path={ContactsUrl}>
                            <ContactsPage />
                        </Route>
                        <Route path="/my">
                            {!loggedIn || userInfo === null ? (
                                <>
                                    <LoginPage
                                        onLogin={(login, token) =>
                                            setAuth(login, token)
                                        }
                                    />
                                    {/* <Redirect to="/login" /> */}
                                </>
                            ) : (
                                <>
                                    {userInfo &&
                                    userInfo.email_verified_at === null ? (
                                        // <Redirect to="/verify" />
                                        <RegistrationVerifyPage />
                                    ) : (
                                        <>
                                            <Route exact path={DashboardUrl}>
                                                <DashboardPage />
                                            </Route>
                                            <Route exact path={MeterReadingUrl}>
                                                <MeterReadingPage />
                                            </Route>
                                            <Route
                                                exact
                                                path={CustomerServiceReportUrl}
                                            >
                                                <CustomerServicePage />
                                            </Route>
                                            <Route exact path={Settings}>
                                                <SettingsPage
                                                    onLogOut={setLogout}
                                                />
                                            </Route>
                                            <Route exact path="/my/templates">
                                                <TemplatesPage />
                                            </Route>
                                            <Route
                                                exact
                                                path="/my/service-reports"
                                            >
                                                <EarlierReportsPage />
                                            </Route>
                                            <Route
                                                exact
                                                path="/my/meter-reports"
                                            >
                                                <EarlierMeterReportsPage />
                                            </Route>
                                            <Route
                                                exact
                                                path="/my/report-complete/:type"
                                            >
                                                <SuccessReportPage />
                                            </Route>
                                        </>
                                    )}
                                </>
                            )}
                        </Route>
                        <Route exact path="/">
                            {isDesktop ? (
                                <Redirect to={DashboardUrl} />
                            ) : (
                                <Redirect to={NewsUrl} />
                            )}
                        </Route>
                        <Route>
                            <NotFoundPage />
                        </Route>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="settings" href={Settings}>
                            <div className="icon-holder">
                                <IonIcon icon={person} />
                            </div>
                            <IonLabel className="tab-menu-text">
                                {isDesktop ? "Profil" : ""}
                            </IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="contacts" href={ContactsUrl}>
                            <div className="icon-holder">
                                <IonIcon icon={mailOpen} />
                            </div>
                            <IonLabel className="tab-menu-text">
                                {isDesktop ? "Kapcsolat" : ""}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton
                            tab={isDesktop || loggedIn ? "dashboard" : "news"}
                            href={
                                isDesktop || loggedIn ? DashboardUrl : NewsUrl
                            }
                        >
                            <div className="icon-holder">
                                <IonIcon icon={home} />
                            </div>
                            <IonLabel className="tab-menu-text">
                                {isDesktop ? "Főoldal" : ""}
                            </IonLabel>
                        </IonTabButton>

                        <IonTabButton
                            tab="customer-service-report"
                            href={CustomerServiceReportUrl}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={documentText} />
                            </div>
                            <IonLabel className="tab-menu-text">
                                {isDesktop ? "Bejelentés" : ""}
                            </IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="meter" href={MeterReadingUrl}>
                            <div className="icon-holder">
                                <IonIcon icon={speedometer} />
                            </div>
                            <IonLabel className="tab-menu-text">
                                {isDesktop ? "Mérőállás" : ""}
                            </IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </>
    );
};

export default AppTabs;
