import React, { useState, useCallback } from "react";

export const AlertContext = React.createContext({
    alert: null,
    addAlert: (title: string, message: string, onDismissHandler?: () => void) => {},
    removeAlert: () => {},
});

export default function AlertProvider({ children }) {
    const [alert, setAlert] = useState(null);

    const removeAlert = () => setAlert(null);

    const addAlert = (title, message, onDismissHandler) => setAlert({ title, message, onDismissHandler });

    const contextValue = {
        alert,
        addAlert: useCallback((title, message, onDismissHandler) => addAlert(title, message, onDismissHandler), []),
        removeAlert: useCallback(() => removeAlert(), []),
    };

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
        </AlertContext.Provider>
    );
}
