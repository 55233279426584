import React from "react";
import { IonToast } from "@ionic/react";
import useToast from "../hooks/useToast";

export default function Toast() {
    const { toast, removeToast } = useToast();

    const handleDismiss = () => {
        removeToast();
    };
    if (!toast || !toast.message) {
        return null;
    }
    return (
        <IonToast
            isOpen={!!toast}
            message={toast.message}
            onDidDismiss={handleDismiss}
            duration={toast.duration}
        />
    );
}
