import React from "react";
import PageWrapper from "../../layout/PageWrapper";
import { IonRow, IonCol, IonCard, IonIcon } from "@ionic/react";
import {
    speedometer,
    documentText,
    person,
    mailOpen,
    earth,
    newspaper,
} from "ionicons/icons";
import "./DashboardPage.scss";
import { Capacitor } from "@capacitor/core";

const DashboardPage: React.FC = () => {
    return (
        <PageWrapper>
            <div className="meter-page">
                <IonRow>
                    <IonCol size="6" sizeMd="4">
                        <IonCard
                            routerLink="/my/send-meter-report"
                            routerDirection="none"
                            className="menu-card"
                            color="primary"
                            button={true}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={speedometer} />
                            </div>
                            Mérőállás
                        </IonCard>
                    </IonCol>
                    <IonCol size="6" sizeMd="4">
                        <IonCard
                            routerLink="/my/send-service-report"
                            routerDirection="none"
                            className="menu-card"
                            color="primary"
                            button={true}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={documentText} />
                            </div>
                            Bejelentés
                        </IonCard>
                    </IonCol>
                    <IonCol size="6" sizeMd="4">
                        <IonCard
                            routerLink="/my/settings"
                            routerDirection="none"
                            className="menu-card"
                            color="primary"
                            button={true}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={person} />
                            </div>
                            Profil
                        </IonCard>
                    </IonCol>
                    <IonCol size="6" sizeMd="4">
                        <IonCard
                            routerLink="/contacts"
                            routerDirection="none"
                            className="menu-card"
                            color="primary"
                            button={true}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={mailOpen} />
                            </div>
                            Kapcsolat
                        </IonCard>
                    </IonCol>
                    <IonCol size="6" sizeMd="4">
                        <IonCard
                            href="https://www.pannon-viz.hu/"
                            className="menu-card"
                            color="primary"
                            button={true}
                        >
                            <div className="icon-holder">
                                <IonIcon icon={earth} />
                            </div>
                            Weboldal
                        </IonCard>
                    </IonCol>
                    {Capacitor.isNative && (
                        <IonCol size="6" sizeMd="4">
                            <IonCard
                                routerLink="/news"
                                routerDirection="none"
                                className="menu-card"
                                color="primary"
                                button={true}
                            >
                                <div className="icon-holder">
                                    <IonIcon icon={newspaper} />
                                </div>
                                Hírek
                            </IonCard>
                        </IonCol>
                    )}
                </IonRow>
            </div>
        </PageWrapper>
    );
};
export default DashboardPage;
