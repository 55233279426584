import React, { useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper";
import { useAuth } from "../../context/AuthContext";
import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import { trash, save } from "ionicons/icons";
import {
    TemplateElement,
    TemplateNameElement,
    ConsumerNameElement,
    ConsumerAddressElement,
    ConsumerIDElement,
    CommentElement,
} from "../../helpers/form/TemplatesElements";
import Input from "../../helpers/form/FormItem";
import { changedHandler } from "../../helpers/Validation";
import useLoading from "../../hooks/useLoading";
import {
    GetFormValues,
    CheckValidation,
} from "../../helpers/form/GetFormValues";
import useToast from "../../hooks/useToast";
import Axios from "axios";
import GetMeterCardInputs from "../../helpers/GetMeterCardInputs";
import FormElement from "../../models/FormElement";
import { add, remove } from "ionicons/icons";
import SetFormValues from "../../helpers/form/SetFormValues";
import ClearFormValues from "../../helpers/form/ClearFormValues";

import "./MeterReadingPage.scss";

const TemplatesPage: React.FC = () => {
    const { addLoading, removeLoading } = useLoading();
    const { addToast } = useToast();
    const { loggedIn, userInfo, token } = useAuth();
    const [meterCount, setMeterCount] = useState(1);
    const [selectedTemplate, setSelectedTemplate] = useState("");

    useEffect(() => {
        if (meterCount > 1) {
            const cardId = "meterReadingCardId" + (meterCount - 1);
            let card = document.getElementById(cardId);
            // card.scrollIntoView();
        }
    }, [meterCount]);

    const updateMeterCount = (isPlus: boolean) => {
        const tempInputData = [...inputData];
        if (isPlus) {
            tempInputData.push(GetMeterCardInputs(true, meterCount));
        } else {
            tempInputData.pop();
        }
        setInputData(tempInputData);
        setMeterCount((prevState) => (isPlus ? prevState + 1 : prevState - 1));
    };

    const [inputDataTemplate, setInputDataTemplate] = useState([
        TemplateElement,
    ]);
    const [inputDataFirst, setInputDataFirst] = useState([
        TemplateNameElement,
        ConsumerNameElement,
        ConsumerAddressElement,
        ConsumerIDElement,
    ]);
    const [inputData, setInputData] = useState([GetMeterCardInputs(true, 0)]);
    const [inputDataLast, setInputDataLast] = useState([CommentElement]);

    useEffect(() => {
        getTemplates();
    }, [loggedIn]);

    useEffect(() => {
        if (selectedTemplate && selectedTemplate != "-1") {
            Axios.get(`/report-template/show/${inputDataTemplate[0].value}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    const loadedDataFirst = SetFormValues(
                        inputDataFirst,
                        res.data.template
                    );
                    setInputDataFirst(loadedDataFirst);

                    const loadedDataLast = SetFormValues(
                        inputDataLast,
                        res.data.template
                    );
                    setInputDataLast(loadedDataLast);

                    let tempInputData = [];
                    if (res.data.template.meters.length) {
                        for (
                            let i = 0;
                            i < res.data.template.meters.length;
                            i++
                        ) {
                            const meterCardData = GetMeterCardInputs(true, i);
                            const loadedMeterCardData = SetFormValues(
                                meterCardData,
                                res.data.template.meters[i]
                            );
                            tempInputData.push(loadedMeterCardData);
                        }
                    } else {
                        tempInputData.push(GetMeterCardInputs(true, 0));
                    }

                    setInputData(tempInputData);
                    setMeterCount(tempInputData.length);

                    removeLoading();
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            ClearFormValues(inputDataFirst, setInputDataFirst);
            ClearFormValues(inputDataLast, setInputDataLast);
            setMetersToDefault();
            removeLoading();
        }
    }, [selectedTemplate]);
    useEffect(() => {
        if (inputDataTemplate[0].value != selectedTemplate) {
            if (inputDataTemplate[0].value) {
                addLoading();
            }
            setSelectedTemplate(inputDataTemplate[0].value);
        }
    }, [inputDataTemplate]);

    function getTemplates() {
        Axios.get("/report-template/list", {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                const tempData = [...inputDataTemplate];
                const tempTemplateElement = { ...tempData[0] };
                let options = [];
                let tempTemplateList = Object.entries(res.data.templates);
                tempTemplateList = tempTemplateList.sort((a, b) =>
                    a[1]["name"] > b[1]["name"]
                        ? 1
                        : b[1]["name"] > a[1]["name"]
                        ? -1
                        : 0
                );
                tempTemplateList.forEach((element) => {
                    options.push({
                        name: element[1]["name"],
                        value: element[1]["id"],
                    });
                });
                tempTemplateElement.value = "";
                tempTemplateElement.elementConfig.options = options;
                if (!options.length) {
                    tempTemplateElement.item.className = "hidden";
                } else {
                    tempTemplateElement.item.className = "";
                }
                tempData[0] = tempTemplateElement;
                setInputDataTemplate(tempData);
                removeLoading();
            })
            .catch((err) => {
                removeLoading();
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const setMetersToDefault = () => {
        let tempInputData = [];
        tempInputData.push(GetMeterCardInputs(true, 0));
        setInputData(tempInputData);
        setMeterCount(1);
    };

    const setMetersInputData = (modifiedInputData: FormElement[]) => {
        const findIndex = modifiedInputData[0].keyname.lastIndexOf("_");
        const index = parseInt(
            modifiedInputData[0].keyname.substring(findIndex + 1)
        );

        const tempData = [...inputData];
        tempData[index] = modifiedInputData;
        setInputData(tempData);
    };

    const handleSave = () => {
        addLoading();
        const isValidFirstPart = CheckValidation(inputDataFirst);
        const isValidLastPart = CheckValidation(inputDataLast);
        if (isValidFirstPart["isValid"] && isValidLastPart["isValid"]) {
            const valuesMeters = [];
            for (let i = 0; i < inputData.length; i++) {
                valuesMeters.push(GetFormValues(inputData[i]));
            }
            const wrongMeters = valuesMeters.filter(
                (x) => x["meter_name"] && !x["meter_id"]
            );
            if (wrongMeters.length) {
                removeLoading();
                addToast(
                    "Mérőóra mentéshez meg kell adni a gyári számát",
                    2000
                );
            } else {
                const valuesTemplate = GetFormValues(inputDataTemplate);
                const valuesFirst = GetFormValues(inputDataFirst);
                const valuesLast = GetFormValues(inputDataLast);
                let values = {
                    ...valuesTemplate,
                    ...valuesFirst,
                    ...valuesLast,
                };
                values["meters"] = valuesMeters;
                const url =
                    values["template"] && values["template"] != "-1"
                        ? `/report-template/update/${values["template"]}`
                        : "/report-template/store";
                Axios.post(url, values, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                    .then((res) => {
                        getTemplates();
                        ClearFormValues(inputDataFirst, setInputDataFirst);
                        ClearFormValues(inputDataLast, setInputDataLast);
                        setMetersToDefault();
                        removeLoading();
                        addToast("Sikeres mentés", 3000);
                    })
                    .catch((err) => {
                        removeLoading();
                    });
            }
        } else {
            if (!isValidFirstPart["isValid"]) {
                setInputDataFirst(isValidFirstPart["elements"]);
            }
            if (!isValidLastPart["isValid"]) {
                setInputDataLast(isValidLastPart["elements"]);
            }
            removeLoading();
        }
    };
    const handleDelete = () => {
        addLoading();
        const values = GetFormValues(inputDataTemplate);
        if (values["template"]) {
            Axios.delete(`/report-template/delete/${values["template"]}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((res) => {
                    getTemplates();
                    ClearFormValues(inputDataFirst, setInputDataFirst);
                    ClearFormValues(inputDataLast, setInputDataLast);
                    setMetersToDefault();
                    removeLoading();
                    addToast("Sikeres törlés", 2000);
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            removeLoading();
            addToast("Sablont kell választani a törléshez", 2000);
        }
    };

    let meterCards = [];
    for (let i = 0; i < meterCount; i++) {
        meterCards.push(i);
    }

    return (
        <PageWrapper>
            <h1 className="page-title">Sablonjaim</h1>

            <form className="form" onSubmit={handleSubmit}>
                {inputDataTemplate.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputDataTemplate,
                                setInputDataTemplate,
                                event
                            )
                        }
                    />
                ))}
                {inputDataFirst.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputDataFirst,
                                setInputDataFirst,
                                event
                            )
                        }
                    />
                ))}

                <br />
                <h3 className="meter-card-title">Mérőóra adatai</h3>
                {inputData.map((meterCardData, i) => (
                    <div className="blue-form" key={i}>
                        <div
                            className="meter-reading-card"
                            id={"meterReadingCardId" + i}
                        >
                            {meterCardData.map((element, j) => (
                                <Input
                                    key={j}
                                    formElement={element}
                                    changed={(event) =>
                                        changedHandler(
                                            j.toString(),
                                            meterCardData,
                                            setMetersInputData,
                                            event
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </div>
                ))}

                <IonRow>
                    {meterCount > 1 ? (
                        <IonCol size="6">
                            <IonButton
                                expand="full"
                                shape="round"
                                onClick={() => updateMeterCount(false)}
                            >
                                <IonIcon slot="icon-only" icon={remove} />
                            </IonButton>
                        </IonCol>
                    ) : null}
                    <IonCol size={meterCount > 1 ? "6" : "12"}>
                        <IonButton
                            expand="full"
                            shape="round"
                            onClick={() => updateMeterCount(true)}
                        >
                            <IonIcon slot="icon-only" icon={add} />
                        </IonButton>
                    </IonCol>
                </IonRow>

                {inputDataLast.map((element, index) => (
                    <Input
                        key={index}
                        formElement={element}
                        changed={(event) =>
                            changedHandler(
                                index.toString(),
                                inputDataLast,
                                setInputDataLast,
                                event
                            )
                        }
                    />
                ))}
                <br />
                <IonRow>
                    <IonCol size="6">
                        <IonButton
                            color="danger"
                            shape="round"
                            expand="full"
                            onClick={handleDelete}
                        >
                            <IonIcon slot="start" icon={trash} />
                            Törlés
                        </IonButton>
                    </IonCol>
                    <IonCol size="6">
                        <IonButton
                            expand="full"
                            shape="round"
                            onClick={handleSave}
                        >
                            <IonIcon slot="start" icon={save} />
                            Mentés
                        </IonButton>
                    </IonCol>
                </IonRow>
            </form>
        </PageWrapper>
    );
};
export default TemplatesPage;
