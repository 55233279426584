import FormElement from "../../models/FormElement";
import { OnlyNumberEvent } from "../../helpers/form/EnabledCharacters";

export const EmailElement: FormElement = {
    keyname: "email",
    type: "input",
    elementConfig: {
        name: "email",
        type: "text",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "E-mail cím",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const NewPasswordElement: FormElement = {
    keyname: "password",
    type: "input",
    elementConfig: {
        name: "password",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    labelText: "Új jelszó",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConfirmNewPasswordElement: FormElement = {
    keyname: "password_confirmation",
    type: "input",
    elementConfig: {
        name: "password_confirmation",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    labelText: "Jelszó megerősítése",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const PinElement: FormElement = {
    keyname: "hash",
    type: "input",
    elementConfig: {
        name: "hash",
        type: "number",
        onKeyDown: OnlyNumberEvent,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "PIN kód",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};