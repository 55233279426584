export function GetFormattedDate(date: Date): string{
    const Year = date.getFullYear();
    let Month = (date.getMonth() + 1).toString();
    let Day = date.getDate().toString();
    if (Day.length < 2) {
        Day = '0' + Day;
    }
    if (Month.length < 2) {
        Month = '0' + Month;
    }
    return Year + "-" + Month + "-" + Day;
}