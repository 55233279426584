import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const Input: React.FC<Props> = ({ formElement, changed }) => {
    let labelElement = null;
    let inputElement = null;
    let endIconElement = null;

    if (formElement.labelConfig || formElement.labelText) {
        labelElement = (
            <IonLabel {...formElement.labelConfig}>
                {formElement.labelText ?? ""}
            </IonLabel>
        );
    }
    inputElement = (
        <IonInput
            {...formElement.elementConfig}
            // onIonChange={changed}
            onIonInput={changed}
            onIonBlur={changed}
            value={formElement.value}
        />
    );
    if (formElement.endIcon) {
        endIconElement = formElement.endIcon;
    }

    return (
        <IonItem {...formElement.item}>
            {labelElement}
            {inputElement}
            {endIconElement}
        </IonItem>
    );
};
export default Input;
