import FormElement from "../../models/FormElement";
import { OnlyNumberEvent } from "../../helpers/form/EnabledCharacters";

export const TemplateElement: FormElement = {
    keyname: "template_id",
    type: "select",
    elementConfig: {
        name: "template_id",
        placeholder: "Sablon kiválasztása...",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Sablon:",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const StatementTypeElement: FormElement = {
    keyname: "service_report_type_id",
    type: "select",
    elementConfig: {
        name: "service_report_type_id",
        placeholder: "Típus választása...",
    },
    labelConfig: {
        position: "floating",
    },
    validity: [
        {
            type: "required",
            message: "Kötelező választani",
        },
    ],
    labelText: "Bejelentés típusa",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerIDElement: FormElement = {
    keyname: "customer_id",
    type: "input",
    elementConfig: {
        name: "customer_id",
        type: "number",
        onKeyDown: OnlyNumberEvent,
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználó azonosító",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerNameElement: FormElement = {
    keyname: "customer_name",
    type: "input",
    elementConfig: {
        name: "customer_name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználó neve",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ConsumerAddressElement: FormElement = {
    keyname: "place_of_consumption",
    type: "input",
    elementConfig: {
        name: "place_of_consumption",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Felhasználási hely címe",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const StatementTextElement: FormElement = {
    keyname: "comment",
    type: "textarea",
    elementConfig: {
        name: "comment",
        placeholder: "Bejelentés szövege...",
        autoGrow: true,
        maxlength: 2000,
    },
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező kitölteni",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
    labelConfig: {
        position: "floating",
    },
    labelText: "Bejelentés",
};
export const TemplateNameElement: FormElement = {
    keyname: "template_name",
    type: "input",
    elementConfig: {
        name: "template_name",
        type: "text",
        autocapitalize: "words",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Sablon neve (pl. Lakás, Nagyi ...)",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
