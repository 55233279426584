export default function GetApiErrorStr(err: any): string {
  if (!err.response) {
    return "Hálózati hiba.";
  } else {
    if (err.response.status === 404) {
      return "404";
    } else if (typeof err.response.data.message === "string") {
      return err.response.data.message;
    } else {
      let errText = "";
      for (const value of Object.values(err.response.data.message)) {
        errText += (errText !== "" ? "<br />" : "") + value;
      }
      return errText;
    }
  }
}
