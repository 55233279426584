import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import Axios from "axios";

import PageWrapper from "../layout/PageWrapper";
import useLoading from "../hooks/useLoading";
import Input from "../helpers/form/FormItem";
import {
    NameElement,
    PasswordConfirmElement,
    EmailElement,
    PasswordElement,
} from "../helpers/form/RegistrationPageElements";
import { changedHandler } from "../helpers/Validation";
import { arrowForward, personAdd } from "ionicons/icons";
import { CheckValidation, GetFormValues } from "../helpers/form/GetFormValues";

import "./RegistrationPage.scss";
import "./FormPages.scss";
import SetPasswordShowIcon from "../helpers/form/SetPasswordShowIcon";

interface Props {
    onRegistration: (login: boolean, token: string) => void;
}

const RegistrationPage: React.FC<Props> = ({ onRegistration }) => {
    const { addLoading, removeLoading } = useLoading();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[2] = SetPasswordShowIcon(
            TempInputData[2],
            showPassword,
            setShowPassword
        );
        setInputData(TempInputData);
    }, [showPassword]);
    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[3] = SetPasswordShowIcon(
            TempInputData[3],
            showConfirmPassword,
            setShowConfirmPassword
        );
        setInputData(TempInputData);
    }, [showConfirmPassword]);

    const [inputData, setInputData] = useState([
        EmailElement,
        NameElement,
        SetPasswordShowIcon(PasswordElement, showPassword, setShowPassword),
        SetPasswordShowIcon(
            PasswordConfirmElement,
            showConfirmPassword,
            setShowConfirmPassword
        ),
    ]);
    useEffect(() => {
        if (
            inputData[3].validity.length !== 2 ||
            inputData[2].value != inputData[3].validity[1].value
        ) {
            let tempInputData = [...inputData];
            let tempPasswordConfirm = { ...tempInputData[3] };
            let tempValidity = [...tempPasswordConfirm.validity];
            if (tempValidity.length === 2) {
                tempValidity.pop();
            }
            tempValidity.push({
                type: "equal",
                value: inputData[2].value,
                message: "A két jelszó nem egyezik",
            });
            tempPasswordConfirm.validity = tempValidity;
            tempInputData[3] = tempPasswordConfirm;
            setInputData(tempInputData);
        }
    }, [inputData]);

    const handleRegistration = async () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            const values = GetFormValues(inputData);
            Axios.post("/register", values)
                .then((res) => {
                    window.location.replace("/verify");
                    onRegistration(true, res.data.access_token);
                    removeLoading();
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            if (!ValidObj["isValid"]) {
                setInputData(ValidObj["elements"]);
            }
            removeLoading();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    // if (loggedIn && userInfo) {
    //     // if (userInfo.email_verified_at) {
    //         return <Redirect to="/dashboard" />;
    //     // } else {
    //     //     return <Redirect to="/verify" />;
    //     // }
    // }

    return (
        <PageWrapper gridClassName="authentication-page-grid">
            <h1 className="page-title">Regisztráció</h1>

            <div className="login-form scroll-content">
                <div className="lower-content">
                    <form className="form" onSubmit={handleSubmit}>
                        {inputData.map((element, index) => (
                            <Input
                                key={index}
                                formElement={element}
                                changed={(event) =>
                                    changedHandler(
                                        index.toString(),
                                        inputData,
                                        setInputData,
                                        event
                                    )
                                }
                            />
                        ))}
                        {/* <IonItem>
                            <IonCheckbox onIonChange={handlePrivacyAccept} />
                            <IonLabel className="ion-text-wrap">
                                Az{" "}
                                <a
                                    target="_blank"
                                    href="https://www.pannon-viz.hu/data/files/adatvedelmi_tajekoztato.pdf"
                                >
                                    adatvédelmi tájékoztatót
                                </a>{" "}
                                elfogadom
                            </IonLabel>
                        </IonItem>
                        {showPrivacyError ? (
                            <IonText color="danger">
                                <strong>
                                    <small>Kötelező elfogadni</small>
                                </strong>
                            </IonText>
                        ) : null} */}
                        <IonText color="light">
                            Amennyiben a „Regisztráció” gombra kattint,
                            kijelenti, hogy elolvasta, megismerte, megértette és
                            tudomásul vette az&nbsp;
                            <a
                                href="https://www.pannon-viz.hu/data/files/adatvedelmi_tajekoztato.pdf"
                                target="_blank"
                            >
                                Adatvédelmi tájékoztató
                            </a>
                            ban foglaltakat, valamint hozzájárul az abban
                            foglaltakhoz.
                        </IonText>

                        <br />
                        <div className="ion-text-center">
                            <IonButton
                                data-testid="loginButton"
                                id="loginButton"
                                size="large"
                                expand="block"
                                shape="round"
                                className="large-btn"
                                onClick={handleRegistration}
                            >
                                <IonIcon
                                    slot="start"
                                    icon={personAdd}
                                ></IonIcon>
                                Regisztráció
                            </IonButton>
                            <br />
                            <p>Van már fiókja?</p>
                            <IonButton
                                size="large"
                                expand="block"
                                shape="round"
                                className="large-btn medium-btn"
                                routerDirection="none"
                                routerLink="/login"
                            >
                                <IonIcon
                                    slot="start"
                                    icon={arrowForward}
                                ></IonIcon>
                                Bejelentkezés
                            </IonButton>
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default RegistrationPage;
