import FormElement from "../../models/FormElement";
import { changedHandler } from "../../helpers/Validation";

export function GetFormValues(elements: FormElement[]) {
    let result = {};
    for (const [key, value] of Object.entries(elements)) {
        result[value.elementConfig.name] = value.value;
    }
    return result;
}

// RESULT
// isValid: validak-e az inputok
// elements: a validált elemek, ezekbe ott vannak már a hibaüzenetek stb, de ha valid minden, akkor nem kell semmit csinálnunk vele, ha mégsem,
// akkor setState aztán kiírja a rendszer a hibákat
export function CheckValidation(elements: FormElement[]): object {
    const tempElements = [...elements];
    for (let i = 0; i < tempElements.length; i++) {
        let tempElement = { ...tempElements[i] };
        tempElement.touched = true;
        tempElements[i] = tempElement;
        tempElements[i] = changedHandler(i.toString(), tempElements);
    }
    return {
        isValid: tempElements.every((x) => x.valid),
        elements: tempElements,
    };
}
