import FormElement from "../../models/FormElement";

export const FromElement: FormElement = {
    keyname: "from",
    type: "date",
    elementConfig: {
        name: "from",
        displayFormat: "YYYY.MM.DD.",
    },
    labelConfig: {
        position: "stacked",
    },
    labelText: "Tól:",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ToElement: FormElement = {
    keyname: "to",
    type: "date",
    elementConfig: {
        name: "to",
        displayFormat: "YYYY.MM.DD.",
    },
    labelConfig: {
        position: "stacked",
    },
    labelText: "Ig:",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const FilterElement: FormElement = {
    keyname: "type",
    type: "select",
    elementConfig: {
        name: "type",
        options: [
            { name: "Bejelentésenként", value: "0" },
            { name: "Sablononként", value: "1" },
            { name: "Sablon nélküli bejelentések", value: "2" },
        ],
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Csoportosítás:",
    value: "0",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const TemplateElement: FormElement = {
    keyname: "template",
    type: "select",
    elementConfig: {
        name: "template",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Sablon:",
    value: "0",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
