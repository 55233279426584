import FormElement from "../../models/FormElement";

export const FromElement: FormElement = {
    keyname: "from",
    type: "date",
    elementConfig: {
        name: "from",
        displayFormat: "YYYY.MM.DD.",
    },
    labelConfig: {
        position: "stacked",
    },
    labelText: "Tól:",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const ToElement: FormElement = {
    keyname: "to",
    type: "date",
    elementConfig: {
        name: "to",
        displayFormat: "YYYY.MM.DD.",
    },
    labelConfig: {
        position: "stacked",
    },
    labelText: "Ig:",
    value: "",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};
export const TypeElement: FormElement = {
    keyname: "type_id",
    type: "select",
    elementConfig: {
        name: "type_id",
        needEmptyItem: true
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Bejelentés típusa:",
    value: "-1",
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {},
};