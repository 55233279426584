import FormElement from "../../models/FormElement";

export const EmailElement: FormElement = {
    keyname: "email",
    type: "input",
    elementConfig: {
        name: "email",
        type: "text",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "E-mail cím vagy felhasználónév",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};
export const PasswordElement: FormElement = {
    keyname: "password",
    type: "input",
    elementConfig: {
        name: "password",
        type: "password",
    },
    labelConfig: {
        position: "floating",
    },
    labelText: "Jelszó",
    value: "",
    validity: [
        {
            type: "required",
            message: "Kötelező mező",
        },
    ],
    valid: false,
    touched: false,
    validationErrorMessage: "",
    item: {
        color: "none",
    },
};
