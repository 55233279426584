import React from "react";
import ReactDOM from "react-dom";
import { setupConfig } from "@ionic/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Axios from "axios";
import AlertProvider from "./context/AlertContext";
import Alert from "./helpers/Alert";
import ToastProvider from "./context/ToastContext";
import Toast from "./helpers/Toast";
import LoadingProvider from "./context/LoadingContext";
import Loading from "./helpers/Loading";
import { SplashScreen } from "@capacitor/splash-screen";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/global.scss";

// Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// Axios.defaults.baseURL = "http://pannonvizcc-laravel-bp.localhost/api/v1";
Axios.defaults.baseURL = "https://ufsz.pannon-viz.hu/api/v1";

setupConfig({
    mode: "ios",
    animated: true,
    hardwareBackButton: false,
});

ReactDOM.render(
    <AlertProvider>
        <Alert />
        <ToastProvider>
            <Toast />
            <LoadingProvider>
                <Loading />
                <App />
            </LoadingProvider>
        </ToastProvider>
    </AlertProvider>,
    document.getElementById("root")
);
SplashScreen.hide();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
