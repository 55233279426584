import { Dispatch, SetStateAction } from "react";
import FormElement from "../../models/FormElement";

export default function ClearFormValues(
    elements: FormElement[],
    setElements?: Dispatch<SetStateAction<FormElement[]>>
) {
    let tempElements = [...elements];
    for (let i = 0; i < tempElements.length; i++) {
        let tempElement = {...tempElements[i]};
        if (
            (tempElements[i].type === "checkbox" &&
                tempElements[i].value !== "0") ||
            (tempElements[i].type !== "checkbox" &&
                tempElements[i].value !== "")
        ) {
            tempElement.forcedClear = true;
        }
        tempElement.value = tempElements[i].type === "checkbox" ? "0" : "";
        tempElement.validationErrorMessage = "";
        tempElement.touched = false;
        tempElements[i] = tempElement;
    }
    if (setElements) {
        setElements(tempElements);
    } else {
        return tempElements;
    }
}
