import { IonText } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";
import Input from "./Input";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Textarea from "./Textarea";
import Select from "./Select";
import Image from "./Image";
import ImagePickerAndCapture from "./ImagePickerAndCapture";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const FormItem: React.FC<Props> = ({ formElement, changed }) => {
    let itemElement = null;
    let validatorElement = null;
    let hintElement = null;
    // let inputClass = "wrap-input1 validate-input ";
    // if (props.touched && props.check && props.valid) {
    //     inputClass = inputClass.concat("alert-validate");
    // }

    switch (formElement.type) {
        case "input":
            itemElement = <Input formElement={formElement} changed={changed} />;
            break;
        case "checkbox":
            itemElement = (
                <Checkbox formElement={formElement} changed={changed} />
            );
            break;
        case "date":
            itemElement = (
                <DatePicker formElement={formElement} changed={changed} />
            );
            break;
        case "textarea":
            itemElement = (
                <Textarea formElement={formElement} changed={changed} />
            );
            break;
        case "select":
            itemElement = (
                <Select formElement={formElement} changed={changed} />
            );
            break;
        case "image":
            itemElement = <Image formElement={formElement} changed={changed} />;
            break;
            case "imagepickerandcapture":
                itemElement = <ImagePickerAndCapture formElement={formElement} changed={changed} />;
                break;
    }

    if (
        formElement.validationErrorMessage &&
        formElement.validationErrorMessage.length
    ) {
        const validatorStyle = formElement.item.color
            ? {
                  backgroundColor:
                      "var(--ion-color-" +
                      formElement.item.color.toString() +
                      ")",
              }
            : null;
        validatorElement = (
            <div style={validatorStyle} className="errorMessage">
                <IonText color="danger">
                    <small>{formElement.validationErrorMessage}</small>
                </IonText>
            </div>
        );
    }

    if (
        formElement.hintMessage &&
        formElement.hintMessage.length
    ) {
        hintElement = (
            <div className="hintMessage">
                <IonText color="primary">
                    <small>{formElement.hintMessage}</small>
                </IonText>
            </div>
        );
    }

    return (
        <div className="form-item">
            {itemElement}
            {validatorElement}
            {hintElement}
        </div>
    );
};

export default FormItem;
