import { IonImg, IonItem } from "@ionic/react";
import React from "react";
import FormElement from "../../models/FormElement";

import "./Image.scss";

interface Props {
    formElement: FormElement;
    changed: (event: any) => void;
}

const Image: React.FC<Props> = ({ formElement, changed }) => {
    let inputElement = null;

    inputElement = (
        <IonImg {...formElement.elementConfig} />
    );

    return (
        <IonItem {...formElement.item}>
            {inputElement}
        </IonItem>
    );
};
export default Image;
